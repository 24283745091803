import React, { Component } from "react";
import ReactTags from "react-tag-autocomplete";
import "./react-tag.css";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { useState, useEffect } from "react";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
// import EcomPreview from "./E-ComPreview/index";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import index from "../ChooseDesign";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import renderHTML from "react-render-html";
import { Editor } from "@tinymce/tinymce-react";
import Modal from "./Modal";

const ShowMore = ({ data }) => {
  const [show, setShow] = useState(true);
  // let data = "fglk;hjfdgl;khjfdglikjhcccccccccccccccccccccccc"
  useEffect(() => {
    console.log(show);
  }, [show]);
  return (
    <>
      {data.length > 40 ? (
        <div>
          {show == true ? (
            <>
              {data.slice(0, 20)}{" "}
              <button
                onClick={() => setShow(false)}
                className="btn btn-success btn-sm"
              >
                Read More
              </button>
            </>
          ) : (
            <>{data}
              <button
                onClick={() => setShow(true)}
                className="btn btn-success btn-sm"
              >
                Read Less
              </button>
            </>
          )}
        </div>
      ) : (
        <div>{data}</div>
      )}
    </>
  );
};

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Shop Service", link: "#" },
        { title: "Add & Manage Shop Service", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      suggestions: [],
      tags: [],
      delimiters: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      //   this page states
      category: [],
      categoryAll: [],
      subCategory: [],
      subCategoryAll: [],
      subServiceAll: [],

      cat_id: "",
      pruduct_type: "",
      sub_cat_id: "",
      sub_srvc_id: "",
      market_price: "",
      selling_price: "",

      // selectedFiles: [],
      images: "",
      uploaded_images: [],
      uploaded_videos: [],
      sell_price: "",
      purchase_price: "",
      discount: "",
      availableSize: [],
      availableColor: [],
      hashtag: "",
      personalisation: "",
      details: "",
      price: "",
      allService: [],
      allService_id: "",

      optionGroupSize: [{ value: "Red", code: "" }],
      selectedMultiSize: [],

      showSelectColor: false,
      showSelect: false,
      colorRgb: "#4667CC",
      simple_color1: false,
      color: [{ code: "#4667CC", active: false }],
      description: "",
      cat_name: "",
      mainimage: false,
      formainimage: [],
      highlightArray: [{ highlight: "" }],
      highlight: [],
      highlightArray: [],
      video: "",
      isOpenLike: false,
      currencyType: [
        {
          name: "INR",
        },
        {
          name: "USD",
        },
        {
          name: "GBP",
        },
        {
          name: "EUR",
        },
        {
          name: "CAD",
        },
      ],
      currency: "",
    };
    this.handleMulti = this.handleMulti.bind(this);
    this.onDragRgb = this.onDragRgb.bind(this);
    this.checkIfNumber = this.checkIfNumber.bind(this);

    // this.reactTags = React.createRef();
  }
  onDragRgb(index, c1) {
    // alert(c1);
    this.state.color[index].code = c1;
    this.setState({ colorRgb: c1 });
  }

  handleMulti = (selectedMultiSize) => {
    this.setState({ selectedMultiSize });
    setTimeout(() => {
      console.log("selectedMulti", selectedMultiSize);
    });
  };

  componentDidMount = async () => {
    this.checkUser();
    this.fetchService();
    this.fetchData();
    // this.fetchCat();
  };
  callbackLike = async () => {
    this.state.isOpenLike = false;
    this.setState({});

    console.log(this.state.isOpenLike);
  };
  fetchService = async () => {
    let result = await HttpClient.requestData("service-category", "GET");
    if (result && result.status > 0) {
      this.setState({ allService: result.data });
    }
    console.log("allService", result);
    console.log("allService", this.state.allService[0]?.name);
    // let resultsub = await HttpClient.requestData("admin/sub-category", "GET");
    // console.log("resultsub", resultsub);
    // if (resultsub && resultsub.status > 0) {
    //   this.setState({ subCategoryAll: resultsub.data });
    // }
  };

  fetchCat = async () => {
    let result = await HttpClient.requestData("category/Category", "GET");
    if (result && result.status > 0) {
      this.setState({ categoryAll: result.data });
    }
    console.log("result cat", result);
    // let resultsub = await HttpClient.requestData("admin/sub-category", "GET");
    // console.log("resultsub", resultsub);
    // if (resultsub && resultsub.status > 0) {
    //   this.setState({ subCategoryAll: resultsub.data });
    // }
  };

  fetchData = async () => {
    let userData = reactLocalStorage.getObject("userData");
    console.log("UserDataId", userData._id);
    let result = await HttpClient.requestData(
      "shop/all-services/" + userData._id,
      "GET"
    );
    console.log("getServiceTableData", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        var dom = new DOMParser().parseFromString(element.details, "text/html");
        var txt = dom.body.textContent;
        let image = element.image.length > 0 ? element.image[0].preview : "";
        let rows = {
          sl: i,
          servicename: element.name,
          price:
            (userData.currency == "INR"
              ? "₹"
              : userData.currency == "CAD"
                ? "$"
                : userData.currency == "USD"
                  ? "$"
                  : userData.currency == "	GBP"
                    ? "£"
                    : "€") + element.price,
          currency: element.currency ? element.currency : "",
          // details: renderHTML(element.details),
          // details: element.details.replace(/<\/?[^>]+(>|$)/g, ""),
          details: <ShowMore data={txt} />,
          video: element.video ? (
            <video width="100%" controls className="rounded-lg">
              <source
                src={HttpClient.IMG_URL + element.video}
                type="video/mp4"
              />
              Your browser does not support HTML video.
            </video>
          ) : (
            ""
          ),
          categoryname: element.category_name ? element.category_name : "",
          img: element.image ? (
            <div>
              <img
                src={HttpClient.IMG_URL + image}
                alt="images"
                className="rounded avatar-md card-img"
              />
            </div>
          ) : null,

          action: (
            <div className="d-flex">
              <button
                title="Delete"
                className="btn btn-danger mr-2 px-2 py-1"
                onClick={() => {
                  this.deleteConfirm(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
              {"  "}
              <button
                title="Edit"
                className="btn btn-primary px-2 py-1"
                onClick={() => {
                  this.edit(element, index);
                  window.scrollTo(0, 0);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </div>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  deleteConfirm = async (item, index) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.delete(item),
        },
        {
          label: "No",
          // onClick: () => alert("Click No"),
        },
      ],
    });
  };
  delete = async (item, index) => {
    let sendData = {};
    let result = await HttpClient.requestData(
      "shop/services/" + item._id,
      "DELETE",
      sendData
    );
    if (result && result.status) {
      // let index = this.state.result.indexOf(item);
      this.setState({
        alert: true,
        message: "Deleted Successfully",
        type: "success",
      });
      this.fetchData();
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
    }
  };
  edit = async (item, index) => {
    console.log("edititems", item);

    this.setState({
      edit_enable: true,
      name: item.name,
      details: item.details,
      price: item.price,
      currency: item.currency,
      tags: item.hashtags,
      highlightArray: item.hasOwnProperty("highlights")
        ? item.highlights
        : [{ highlight: "Default" }],
      personalisation: item.personalization,

      edit_item_id: item._id,
      allService_id: item.category_id,
      cat_name: item.category_name,

      uploaded_images: item.image,
      video: item.video,
    });
  };
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/product/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Succfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  onDelete(i) {
    // const tags = this.state.tags.slice(0)
    // tags.splice(i, 1)
    // this.setState({ tags })
    // console.log(this.state.tags);
    this.state.tags.splice(i, 1);
    this.setState({ tags: this.state.tags });
    console.log(this.state.tags);
  }
  onDeleteHighlight(i) {
    // const tags = this.state.tags.slice(0)
    // tags.splice(i, 1)
    // this.setState({ tags })
    // console.log(this.state.tags);
    this.state.highlightArray.splice(i, 1);
    this.setState({ highlightArray: this.state.highlightArray });
    console.log(this.state.highlightArray);
  }

  onAddition(tag) {
    console.log(tag);
    this.state.tags.push(tag);
    this.setState({ tags: this.state.tags });
    console.log("tags", this.state.tags);
  }
  onAdditionHighlight(tag) {
    console.log(tag);
    this.state.highlightArray.push(tag);
    this.setState({ highlightArray: this.state.highlightArray });
    console.log("highlightArray", this.state.highlightArray);
  }

  submit = async () => {
    // console.log("hiiii", this.state.allService_id);
    // console.log("hiiii", this.state.tags);
    let userData = reactLocalStorage.getObject("userData");
    // console.log("use", userData);
    if (
      this.state.name !== "" &&
      this.state.allService_id !== "" &&
      this.state.details !== "" &&
      this.state.price !== "" &&
      this.state.tags.length > 0
    ) {
      if (this.state.uploaded_images.length > 0) {
        this.setState({ loading: true });

        let data = null;
        let result = null;

        if (this.state.edit_enable) {
          let data = new FormData();
          data.append("name", this.state.name);
          data.append("details", this.state.details);
          data.append("user_id", userData._id);
          data.append("price", this.state.price);
          data.append("currency", userData.currency);
          data.append("hashtags", JSON.stringify(this.state.tags));
          data.append("highlights", JSON.stringify(this.state.highlightArray));
          data.append("category_name", this.state.cat_name);
          // data.append("personalization", this.state.personalisation);
          data.append("video", this.state.video);

          // if (this.state.uploaded_images !== "") {
          //   // console.log("vtd");
          data.append("image", JSON.stringify(this.state.uploaded_images));
          // }

          data.append("category_id", this.state.allService_id);
          console.log("gggg", data);

          result = await HttpClient.FromAdd(
            "shop/services/" + this.state.edit_item_id,
            "PUT",
            data
          );
        } else {
          let data = new FormData();
          data.append("name", this.state.name);
          data.append("details", this.state.details);
          data.append("user_id", userData._id);
          data.append("price", this.state.price);
          data.append("currency", userData.currency);
          data.append("hashtags", JSON.stringify(this.state.tags));
          data.append("highlights", JSON.stringify(this.state.highlightArray));
          //data.append("personalization", this.state.personalisation);
          data.append("image", JSON.stringify(this.state.uploaded_images));
          data.append("category_name", this.state.cat_name);
          data.append("category_id", this.state.allService_id);
          data.append("video", this.state.video);

          console.log("data", data);

          result = await HttpClient.FromAdd("shop/services", "POST", data);
        }
        console.log("createService", result);

        if (result && result.status) {
          this.setState({
            alert: true,
            message: this.state.edit_enable
              ? "Service Updated Successfully"
              : "Service created successfully",
            type: "success",
            name: "",
            details: "",
            price: "",
            hashtag: "",
            currency: "",
            personalisation: "",
            allService_id: "",
            uploaded_images: [],
            // images: {},
            edit_enable: false,
            edit_item_id: "",
            tags: [],
            highlightArray: [],
            video: "",
          });

          this.fetchData();
        } else {
          this.setState({
            alert: true,
            message: result.message,
            type: "danger",
          });
        }
      } else {
        this.setState({
          alert: true,
          message: "Please Select Atleast One Image.",
          type: "warning",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };
  handleAcceptedFiles = async (files) => {
    if (files[0].size < 2e6) {
      let data = new FormData();
      data.append("image", files[0]);

      let result = await HttpClient.fileUplode(
        "shop-service-images",
        "POST",
        data
      );
      console.log("result", result);

      this.state.uploaded_images.push({
        preview: result.data,
        ismain: false,
        type: "image",
      });
      console.log("image", files[0]);

      this.setState({
        images: files[0],
        uploaded_images: this.state.uploaded_images,
      });
      console.log("uploaded_images", this.state.uploaded_images);
    } else {
      this.setState({
        alert: true,
        message: "image size can't be 2Mb",
        type: "warning",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 2000);
    }
  };

  uploadVideo = async (event) => {
    console.log("video content", event.target.files[0]);
    const fsize = event.target.files[0].size;
    const file = Math.round(fsize / 1024);
    console.log("file size", file);
    if (file > 7713) {
      document.getElementById("myInputFileID").value = null;
      this.setState({
        alert: true,
        message: "Please Select Video size less than 10mb.",
        type: "warning",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
    } else {
      let data = new FormData();
      data.append("video", event.target.files[0]);

      let result = await HttpClient.fileUplode(
        "shop-service-video",
        "POST",
        data
      );
      console.log("result", result);
      this.state.video = result.data;
      this.setState({});
    }
  };

  async remove_video() {
    this.setState({ video: "" });
  }

  async remove_image(item, index) {
    // this.state.selectedFiles.pop(index);
    // this.state.images.pop(index);
    this.setState({
      images: {},
    });
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  handleChange = async (event) => {
    this.setState({ cat_id: event.target.value });
  };
  handleCurrencyChange = (event) => {
    this.setState({ currency: event.target.value });
  };

  checkIfNumber = (event) => {

    /**
     * Allowing: Integers | Backspace | Tab | Delete | Left & Right arrow keys
     **/

    const regex = new RegExp(/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);

    return !event.key.match(regex) && event.preventDefault();
  }

  handleServiceChange = async (event) => {
    let name = "";
    let tr = this.state.allService.filter(
      (item) => item._id == event.target.value
    );
    console.log(tr);
    if (tr.length > 0) {
      name = tr[0].name;
    }
    this.setState({
      allService_id: event.target.value,
      cat_name: name,
    });
  };



  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Category Name",
          field: "categoryname",
          sort: "asc",
          width: 270,
        },
        {
          label: "Service Name",
          field: "servicename",
          sort: "asc",
          width: 270,
        },
        {
          label: "Price",
          field: "price",
          sort: "asc",
          width: 270,
        },
        {
          label: "Currency",
          field: "currency",
          sort: "asc",
          width: 270,
        },
        {
          label: "Details",
          field: "details",
          sort: "asc",
          width: 270,
        },
        {
          label: "Image",
          field: "img",
          sort: "asc",
          width: 270,
        },
        {
          label: "Video",
          field: "video",
          sort: "asc",
          width: 270,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              {/* <BaseInfo /> */}
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Category
                      </Label>
                      <Col md={8}>
                        <select
                          className="form-control"
                          onChange={this.handleServiceChange}
                          value={this.state.allService_id}
                        >
                          <option value="">Select Category</option>
                          {this.state.allService.map((item, index) => {
                            return (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                      <Col md={2}>
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.setState({
                              isOpenLike: true,
                            });
                          }}
                        >
                          Suggest Category
                          {/* <i className="ri-arrow-right-line align-middle ml-1"></i> */}
                        </Button>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Service Name
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          id="example-text-input"
                          placeholder="Enter Service Name"
                          onChange={(val) => {
                            if (
                              val.target.value.match("^[a-zA-Z0-9 ]*$") != null
                            ) {
                              this.setState({ name: val.target.value });
                            }
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Price($)
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="number"
                          value={this.state.price}
                          id="example-text-input"
                          placeholder="Enter Price"
                          onChange={(val) => {
                            this.setState({ price: val.target.value });
                          }}
                          onKeyDown={(event) => this.checkIfNumber(event)}
                        />
                      </Col>
                    </FormGroup>
                    {/* <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Currency
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={this.handleCurrencyChange}
                          value={this.state.currency}
                        >
                          <option value="">Select Currency</option>
                          {this.state.currencyType.map((item, index) => {
                            return (
                              <option value={item.name} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </FormGroup> */}
                    {/* <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Market Price
                      </Label>
                      <Col md={10}>
                      <Input
                          className="form-control"
                          type="text"
                          value={this.state.market_price}
                          id="example-text-input"
                          placeholder="Enter Market Price"
                          onChange={(val) => {
                            if (val.target.value.match("^[0-9]*$") != null) {
                              this.setState({ market_price: val.target.value });
                            }
                            
                          }}
                        />
                      </Col>
                    </FormGroup> */}
                    {/* <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                       Selling Price
                      </Label>
                      <Col md={10}>
                      <Input
                          className="form-control"
                          type="text"
                          value={this.state.selling_price}
                          id="example-text-input"
                          placeholder="Enter Selling Price"
                          onChange={(val) => {
                            if (val.target.value.match("^[0-9]*$") != null) {
                              this.setState({ selling_price: val.target.value });
                            }
                           
                          }}
                        />
                      </Col>
                    </FormGroup> */}
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Details
                      </Label>
                      <Col md={10}>
                        {/* <textarea
                          id="question-input1"
                          className="form-control"

                          placeholder="Enter Your Details"
                          onChange={(val) => {

                            this.setState({ details: val.target.value });

                          }}
                          value={this.state.details}
                        ></textarea> */}
                        {/* <CKEditor
                          editor={ClassicEditor}
                          data={this.state.description}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            console.log({ event, editor, data });
                            this.setState({ description: data });
                          }}
                          value={renderHTML(this.state.description)}
                        /> */}
                        <Editor
                          apiKey="15r14a210jticff5jycrmnqijiset548njfyasvp7vzdk3hg"
                          value={this.state.details}
                          onEditorChange={(newValue, editor) => {
                            this.setState({ details: newValue });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Image
                      </Label>
                      <Col md={10}>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {this.state.uploaded_images.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        alt=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        // alt={f.name}
                                        src={HttpClient.IMG_URL + f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Button
                                        className="btn-icon"
                                        color="danger"
                                        style={{ marginRight: 25 }}
                                        onClick={(e) => this.remove_image(f, i)}
                                      >
                                        {" "}
                                        <span className="btn-icon-label">
                                          <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                        </span>{" "}
                                        Remove
                                      </Button>
                                      <input
                                        id="image"
                                        name="imageMethod"
                                        type="radio"
                                        //value={this.state.mainimg}
                                        checked={f.ismain ? true : false}
                                        onChange={(val) => {
                                          let arr = [];
                                          /* let dom = {
                                            mainimage: true,
                                            image: f.preview
                                          }

                                          arr.push(
                                            dom
                                          );
                                          console.log("dom", arr); */
                                          console.log("index", i);
                                          this.state.uploaded_images[
                                            i
                                          ].ismain = true;
                                          this.setState({});
                                          for (
                                            let j = 0;
                                            j <
                                            this.state.uploaded_images.length;
                                            j++
                                          ) {
                                            if (j != i) {
                                              this.state.uploaded_images[
                                                j
                                              ].ismain = false;
                                            }
                                          }
                                          this.setState({});
                                          console.log(
                                            "img array",
                                            this.state.uploaded_images
                                          );
                                          /*  this.setState({
                                             formainimage: arr
                                           }) */
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Video <br />
                        <span style={{ color: "red", fontSize: "12px" }}>
                          Less than10MB
                        </span>
                      </Label>
                      <Col md={4}>
                        <Input
                          className="form-control"
                          type="file"
                          id="myInputFileID"
                          onChange={(e) => {
                            this.uploadVideo(e);
                          }}
                        />
                      </Col>
                      {this.state.video != "" ? (
                        <Row className="align-items-center col-md-6">
                          <Col md={8}>
                            <video width="100%" controls className="rounded-lg">
                              <source
                                src={HttpClient.IMG_URL + this.state.video}
                                type="video/mp4"
                              />
                              Your browser does not support HTML video.
                            </video>
                          </Col>
                          <Col md={4}>
                            <Button
                              className="btn-icon"
                              color="danger"
                              style={{ marginRight: 25 }}
                              onClick={(e) => this.remove_video()}
                            >
                              {" "}
                              <span className="btn-icon-label">
                                <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                              </span>{" "}
                              Remove
                            </Button>
                          </Col>
                        </Row>
                      ) : null}
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Hashtag
                      </Label>
                      <Col md={10}>
                        {/* <textarea
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder="Enter Hashtags"
                          onChange={(val) => {
                            this.setState({ hashtag: val.target.value });
                          }}
                          value={this.state.hashtag}
                        ></textarea> */}
                        <ReactTags
                          autocomplete="off"
                          // ref={this.reactTags}
                          placeholderText="Add new Hashtags..."
                          onInput={(query) => {
                            console.log("query", query);
                            let dom = {
                              id: this.state.suggestions.length + 1,
                              name: query.toUpperCase(),
                            };
                            console.log(dom);
                            this.state.suggestions.push(dom);

                            this.setState({});
                          }}
                          tags={this.state.tags}
                          suggestions={this.state.suggestions}
                          onDelete={this.onDelete.bind(this)}
                          onAddition={this.onAddition.bind(this)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Highlights
                      </Label>
                      <Col md={10}>
                        <ReactTags
                          autocomplete="off"
                          // ref={this.reactTags}
                          placeholderText="Add Highlights..."
                          onInput={(query) => {
                            console.log("query", query);
                            let dom = {
                              id: this.state.highlight.length + 1,
                              name: query,
                            };
                            console.log(dom);
                            this.state.highlight.push(dom);

                            this.setState({});
                          }}
                          tags={this.state.highlightArray}
                          suggestions={this.state.highlight}
                          onDelete={this.onDeleteHighlight.bind(this)}
                          onAddition={this.onAdditionHighlight.bind(this)}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                {/* <div>
                    <EcomPreview />
                  </div> */}
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
          {this.state.isOpenLike ? (
            <Modal
              isOpen={this.state.isOpenLike}
              closeCallback={this.callbackLike}
            />
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
