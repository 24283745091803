import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Firebase from "./firebase";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import Modal from "./modal";
import BellIcon from "react-bell-icon";
//import ModalPendingBooking from "./ModalPendingBooking";



class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Chat List", link: "#" },
        { title: "Manage Chat List", link: "#" },
      ],

      data: [],
      alert: false,
      message: "",
      type: "",
      chatlist: [],


      isOpenLike: false,
      roomid: "",
      name: "",
      fullname: "",
      userid: "",
      sessionclose: false,
    };
    // this.handleDefault = this.handleDefault.bind(this);
  }



  componentDidMount = async () => {
    this.checkUser();

  };

  callbackLike = async () => {
    this.state.isOpenLike = false;
    this.setState({});

    console.log(this.state.isOpenLike);
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    console.log("user", user);
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user._id,
        name: user.firstName + " " + user.lastName
      });
      this.fetchData(user._id);

    }

  };


  fetchData = async (id) => {
    console.log("id", id);
    Firebase.database().ref().child("chatlist").child(this.state.userId + "S").on("value", (snapshot) => {
      if (snapshot.exists()) {
        console.log(snapshot.val());
        let sl = 1;
        let arr = [];
        snapshot.forEach((ele) => {
          console.log("val", ele.val());
          let dom = {
            sl: sl,
            name: ele.val().name,
            sessionclose: ele.val().sessionclose,
            lastMsg: ele.val().lastMsg != "" ? ele.val().lastMsg
              :
              <img
                data-dz-thumbnail
                src={HttpClient.IMG_URL + ele.val().forimage}
                className="avatar-md rounded bg-light"
                alt="preview"
              />
            ,
            bell: (
              <BellIcon
                active={ele.val().lastMsgBy == "User" ? true : false}
                animate={ele.val().lastMsgBy == "User" ? true : false}
                color={ele.val().lastMsgBy == "User" ? "#FF3333" : "#0c89e9"}
                width={20}
                height={20}
              />
            ),
            reply: (
              <Button
                className="info"
                outline
                onClick={() => {
                  this.state.isOpenLike = true;
                  this.setState({

                    roomid: ele.val().roomid,
                    userid: ele.val().userId,
                    fullname: ele.val().name,
                    sessionclose: ele.val().sessionclose


                  });
                  console.log(" isOpenLike", this.state.isOpenLike);
                }}
              >
                {ele.val().sessionclose ? "Chat Session Ended View Messeages" : "Reply"}
              </Button>
            ),

          }
          sl++;
          arr.push(dom);
          console.log(arr);

        }

        );
        this.state.chatlist = arr;
        this.setState({});

      } else {
        console.log("No data available");
      }
    })
  }



  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },

        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        //  {
        //   label: "Email",
        //   field: "email",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "Notification Bell",
          field: "bell",
          sort: "asc",
          width: 270,
        },

        {
          label: "Last Message",
          field: "lastMsg",
          sort: "asc",
          width: 270,
        },
        {
          label: "Action",
          field: "reply",
          sort: "asc",
          width: 100,
        },


      ],
      rows: this.state.chatlist,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col lg={12}>
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.message}
                  </UncontrolledAlert>
                ) : null}
              </Col>
            </Row>

            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>{" "}
          {this.state.isOpenLike ? (
            <Modal
              status={true}
              onCloseCallBack={this.callbackLike}
              roomid={this.state.roomid}
              image=""
              name={this.state.name}
              fullname={this.state.fullname}
              selleruserid={this.state.userId}
              userid={this.state.userid}
              sessionclose={this.state.sessionclose}
            />
          ) : null}
        </div>

      </React.Fragment>
    );
  }
}

export default FormElements;