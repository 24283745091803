import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
// import EcomPreview from "./E-ComPreview/index";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import index from "../ChooseDesign";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Create Slot", link: "#" },
        { title: "Add & Manage Create Slot", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      //   this page states
      category: [],
      categoryAll: [],
      subCategory: [],
      subCategoryAll: [],
      subServiceAll: [],

      cat_id: "",
      pruduct_type: "",
      sub_cat_id: "",
      sub_srvc_id: "",
      market_price: "",
      selling_price: "",

      // selectedFiles: [],
      images: "",
      uploaded_images: [],

      sell_price: "",
      purchase_price: "",
      discount: "",
      availableSize: [],
      availableColor: [],
      hashtag: "",
      personalisation: "",
      details: "",
      price: "",
      allService: [],
      shopService: [],
      allService_id: "",
      allShopService_id: "",
      to_time: "",
      from_time: "",
      available_duration: 0,
      slot_duration_id: "",

      optionGroupSize: [{ value: "Red", code: "" }],
      selectedMultiSize: [],

      showSelectColor: false,
      showSelect: false,
      colorRgb: "#4667CC",
      simple_color1: false,
      color: [{ code: "#4667CC", active: false }],
      description: "",
      selectcategory: "",
      selectshopserviceid: "",
      edit_id: "",
      weekDay: [
        { label: "Monday", value: "Monday" },
        { label: "Tuesday", value: "Tuesday" },
        { label: "Wednesday", value: "Wednesday" },
        { label: "Thursday", value: "Thursday" },
        { label: "Friday", value: "Friday" },
        { label: "Saturday", value: "Saturday" },
        { label: "Sunday", value: "Sunday" },
      ],
      slot_duration: [
        { label: "10", value: "10" },
        { label: "15", value: "15" },
        { label: "30", value: "30" },
      ],
      language: [
        { label: "en", value: "en" }
      ],
      lang: "",
      slot_time: "",
      selectedWeek: "",
      selectWeekName: "",
    };
    this.handleMulti = this.handleMulti.bind(this);
    this.onDragRgb = this.onDragRgb.bind(this);
  }
  onDragRgb(index, c1) {
    // alert(c1);
    this.state.color[index].code = c1;
    this.setState({ colorRgb: c1 });
  }

  handleMulti = (selectedMultiSize) => {
    this.setState({ selectedMultiSize });
    setTimeout(() => {
      console.log("selectedMulti", selectedMultiSize);
    });
  };

  componentDidMount = async () => {
    this.checkUser();
    this.fetchService();
    // this.fetchData();
    this.fetchServicecategory();
    let sellerData = reactLocalStorage.getObject("userData");
    console.log(sellerData);
    // this.fetchCat();
  };

  fetchService = async () => {
    let result = await HttpClient.requestData("service-category", "GET");
    if (result && result.status > 0) {
      this.setState({ allService: result.data });
    }
    console.log("allService", result);
    // let resultsub = await HttpClient.requestData("admin/sub-category", "GET");
    // console.log("resultsub", resultsub);
    // if (resultsub && resultsub.status > 0) {
    //   this.setState({ subCategoryAll: resultsub.data });
    // }
  };

  fetchServicecategory = async (id) => {
    console.log(id);
    let userData = reactLocalStorage.getObject("userData");
    console.log(userData._id)
    let result = await HttpClient.requestData(
      "shop/view-shopservice/" + userData._id + "/" + id,
      "GET"
    );
    if (result && result.status > 0) {
      this.setState({ shopService: result.data });
    }
    console.log("shopService", result);

  };

  fetchData = async (id) => {
    console.log("hii",id);
    // console.log();
    console.log(this.state.selectshopserviceid);
    let userData = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData(
      "shop-service/weekly-timings/" + id,
      "GET"
    );
    console.log("getSlotData", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          slot: element.slot_duration,
          name: element.day_name,
          from: element.from,
          to: element.to,
          available_duration: element.available_duration + " Hrs",

          action: (
            <>
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  // this.delete(element, index);
                  this.deleteConfirm(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  window.scrollTo(0,0);
                  this.edit(element, index);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  deleteConfirm = async (item, index) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.delete(item),
        },
        {
          label: "No",
          // onClick: () => alert("Click No"),
        },
      ],
    });
  };
  delete = async (item, index) => {

    let result = await HttpClient.requestData(
      "shop-service/timing/" + item._id,
      "DELETE",

    );
    console.log(result);
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "Deleted Successfully",
        type: "success",
      });

      this.fetchData(this.state.selectshopserviceid);
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
    }
  };
  edit = async (item, index) => {
    console.log("edititems", item);
    this.setState({
      slot_time: item.slot_duration,
      // selectWeekName: item.day_name,
      from_time: item.from,
      to_time: item.to,
      available_duration: item.available_duration,
      allShopService_id: item.shop_service_id,
      allService_id: item.category_id,
      edit_enable: true,
      edit_id: item._id,
      lang: item.language,
      selectWeekName: {
        label: item.day_name,
        value: item.day_name
      }
      // slot_time:
    });
    console.log("day", this.state.selectWeekName);
  };
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/product/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Succfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      console.log("chunk", this.state.selectshopserviceid);
      this.fetchData(this.state.selectshopserviceid);
    }
  };

  submit = async () => {
    let sellerData = reactLocalStorage.getObject("userData");
    console.log(sellerData);
    if (
      this.state.selectWeekName != "" &&
      // this.state.available_duration != "" &&
      this.state.from_time != "" &&
      this.state.to_time != "" &&
      this.state.allService_id != "" &&
      this.state.allShopService_id != "" &&
      this.state.slot_time != ""

    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          slot_duration: parseInt(this.state.slot_time),
          day_name: this.state.selectWeekName,
          from: this.state.from_time,
          to: this.state.to_time,
          language: "en",
          //available_duration: this.state.available_duration,
          shop_service_id: this.state.allShopService_id,
          category_id: this.state.allService_id,
          seller_id: sellerData._id,
          // language: this.state.lang
        };
        console.log("add-----",data);
        // return false
        result = await HttpClient.requestData(
          "shop-service/timing",
          "POST",
          data
        );
      } else {
        data = {
          slot_duration: parseInt(this.state.slot_time),
          // day_name: this.state.selectWeekName,
          from: this.state.from_time,
          to: this.state.to_time,
          language: "en",
          //available_duration: this.state.available_duration,
          shop_service_id: this.state.allShopService_id,
          category_id: this.state.allService_id,
          seller_id: sellerData._id,
          // language: this.state.lang
        };
        console.log("edit-----",data);
        result = await HttpClient.requestData(
          "shop-service/timing/" + this.state.edit_id,
          "PUT",
          data
        );
      }
      console.log("result1", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Slot Updated Successfully"
            : "Slot Added Successfully",

          type: "success",
          selectWeekName: "",
          from_time: "",
          to_time: "",
          available_duration: "",
          allShopService_id: "",
          allService_id: "",
          slot_time: "",
          lang: ""
        });

        this.fetchData(this.state.selectshopserviceid);
      } else {
        this.setState({
          alert: true,
          message: result.message,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };
  handleAcceptedFiles = (files) => {
    // console.log("object", event.target.files[0]);
    // setprofile(event.target.files[0]);
    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
    };
    console.log("image", files[0]);
    let arr = [];
    arr.push(file);
    this.setState({
      images: files[0],
      uploaded_images: arr,
    });
    console.log("uploaded_images", this.state.uploaded_images);
  };

  async remove_image(item, index) {
    // this.state.selectedFiles.pop(index);
    // this.state.images.pop(index);
    this.setState({
      images: {},
    });
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
      console.log(user.id);
    } else {
    }
    // this.fetchData();
  };

  handleChange = async (event) => {

    this.setState({ cat_id: event.target.value });

  };
  handleServiceChange = async (event) => {

    let selectedservice = event.target.value;
    console.log(selectedservice);
    this.setState({
      allService_id: selectedservice,
      allShopService_id: ""
    });
    console.log(this.state.allService_id);
    this.fetchServicecategory(selectedservice);
  };

  handleCat = async (event) => {
    this.state.selectcategory = event.target.value;
    this.setState({});
    this.servicefetch(event.target.value);
  }

  handleShopServiceChange = async (event) => {
    let selectedShopservice = event.target.value;
    this.setState({ allShopService_id: selectedShopservice });

  };

  handelservice = async (event) => {
    this.state.selectshopserviceid = event.target.value;
    this.setState({});
    this.fetchData(event.target.value);
  }
  handleWeekChange = async (selected) => {
    // console.log("day", event.target.value);
    // this.state.selectWeekName = event.target.value;
    // console.log("select Week", this.state.selectWeekName);
    // this.setState({});

    console.log("day", selected);
    this.state.selectWeekName = selected;
    console.log("select Week", this.state.selectWeekName);
    this.setState({});


  }
  handleSlotChange = async (event) => {
    this.state.slot_time = event.target.value;
    this.setState({});
    console.log("slot Time", this.state.slot_time);
  };
  handleLangChange = async (event) => {
    this.state.lang = event.target.value;
    this.setState({});
    console.log("slot Time", this.state.lang);
  };

  servicefetch = async (id) => {
    console.log(id);
    let userData = reactLocalStorage.getObject("userData");
    console.log(userData._id)
    let result = await HttpClient.requestData(
      "shop/view-shopservice/" + userData._id + "/" + id,
      "GET"
    );
    if (result && result.status > 0) {
      this.setState({ shopService: result.data });
    }
    console.log("shopService", result);

  };



  getDuration = async (endtime) => {

    let start_time = moment(this.state.from_time, "HH:mm");
    console.log(start_time);
    let end_time = moment(endtime, "HH:mm");
    console.log(end_time);
    if (end_time.isAfter(start_time)) {
      let duration = moment.duration(end_time.diff(start_time));
      var hours = parseInt(duration.asHours());
      console.log(hours);
      this.state.available_duration = hours;
      this.setState({});
    }
    else {

      this.setState({
        alert: true,
        message: "Start time can't be greater than endtime.",
        type: "warning",
        available_duration: 0
      });
    }
  }
  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Week Day",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Slot",
          field: "slot",
          sort: "asc",
          width: 270,
        },
        {
          label: "From",
          field: "from",
          sort: "asc",
          width: 270,
        },
        {
          label: "To",
          field: "to",
          sort: "asc",
          width: 270,
        },

        // {
        //   label: "Available Duration",
        //   field: "available_duration",
        //   sort: "asc",
        //   width: 270,
        // },
        //   {
        //     label: "Service",
        //     field: "service",
        //     sort: "asc",
        //     width: 270,
        //   },
        // {
        //   label: "Category",
        //   field: "category_id",
        //   sort: "asc",
        //   width: 270,
        // },
        // {
        //   label: "Status",
        //   field: "status",
        //   sort: "asc",
        //   width: 100,
        // },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              {/* <BaseInfo /> */}
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}


                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Category
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={this.handleServiceChange}
                          value={this.state.allService_id}
                        >
                          <option value="">Select Category</option>
                          {this.state.allService.map((item, index) => {
                            return (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Shop Service
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={this.handleShopServiceChange}
                          value={this.state.allShopService_id}
                        >
                          <option value="">Select Shop Service</option>
                          {this.state.shopService.map((item, index) => {
                            return (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Week Day Name
                      </Label>
                      <Col md={10}>
                        {/* <select
                          className="form-control"
                          onChange={this.handleWeekChange}
                          value={this.state.selectWeekName}
                        >
                          <option value="">Select Week Day</option>
                          {this.state.weekDay.map((item, index) => {
                            return (
                              <option value={item.value} key={index}>
                                {item.label}
                              </option>
                            );
                          })}
                        </select> */}

                        <Select
                          isMulti
                          options={this.state.weekDay}
                          onChange={
                            this.handleWeekChange
                          }
                          // isDisabled={this.state.edit_id != "" ? true : false}
                          value={this.state.selectWeekName}
                        />
                      </Col>
                    </FormGroup>
                    {/* <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Language
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={this.handleLangChange}
                          value={this.state.lang}
                        >
                          <option value="">Select Language</option>
                          {this.state.language.map((item, index) => {
                            return (
                              <option value={item.value} key={index}>
                                {item.label}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </FormGroup> */}

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        From
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="time"
                          value={this.state.from_time}
                          id="example-text-input"
                          placeholder="Enter Price"
                          onChange={(val) => {
                            console.log(val.target.value);
                            this.setState({ from_time: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        To
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="time"
                          value={this.state.to_time}
                          id="example-text-input"
                          placeholder="Enter Price"
                          onChange={(val) => {
                            console.log(val.target.value);
                            if (this.state.from_time != "") {
                              this.setState({ to_time: val.target.value });
                              this.getDuration(val.target.value);
                            }
                            else {
                              this.setState({
                                alert: true,
                                message: "select start time first",
                                type: "warning",
                              });
                            }
                            setTimeout(() => {
                              this.setState({
                                alert: false,
                                message: "",
                                type: "",
                              });
                            }, 3000);
                          }}
                        />
                      </Col>
                    </FormGroup>
                    {/* <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Available Duration(hrs)
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          disabled
                          type="text"
                          value={this.state.available_duration}
                          id="example-text-input"
                          placeholder="Enter Available Duration"
                          onChange={(val) => {
                            this.setState({
                              available_duration: val.target.value,
                            });
                          }}
                        />
                      </Col>
                    </FormGroup> */}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Slot Duration(mins)
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={this.handleSlotChange}
                          value={this.state.slot_time}
                        >
                          <option value="">Select Slot Duration</option>
                          {this.state.slot_duration.map((item, index) => {
                            return (
                              <option value={item.value} key={index}>
                                {item.label}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </FormGroup>


                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                {/* <div>
                    <EcomPreview />
                  </div> */}
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <FormGroup row>
                    <Label
                      htmlFor="example-text-input"
                      className="col-md-2 col-form-label"
                    >
                      Category
                    </Label>
                    <Col md={10}>
                      <select
                        className="form-control"
                        onChange={this.handleCat}
                        value={this.state.selectcategory}
                      >
                        <option value="">Select Category</option>
                        {this.state.allService.map((item, index) => {
                          return (
                            <option value={item._id} key={index}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label
                      htmlFor="example-text-input"
                      className="col-md-2 col-form-label"
                    >
                      Select Service
                    </Label>
                    <Col md={10}>
                      <select
                        className="form-control"
                        onChange={this.handelservice}
                        value={this.state.selectshopserviceid}
                      >
                        <option value="">Select Shop Service</option>
                        {this.state.shopService.map((item, index) => {
                          return (
                            <option value={item._id} key={index}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </Col>
                  </FormGroup>
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
