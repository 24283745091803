import React, { useEffect, useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import HttpClient from "../../utils/HttpClient";
import { Card, CardGroup, Col, Container, Row } from "reactstrap";
import MyCard from "./MyCard";
import "./Card.css";
// import Card from 'react-bootstrap/Card';
// import CardGroup from 'react-bootstrap/CardGroup';
import DescriptionPopup from "./DescriptionPopup";
import SubscriptionPopUp from "./SubscriptionPopUp";
import Swal from "sweetalert2";
const Index = () => {
  const history = useHistory();
  const pack = [
    { time: "1 MONTH", price: "15" },
    { time: "3 MONTHS", price: "40" },
    { time: "6 MONTHS", price: "70" },
  ];

  const [subscription, setSubscription] = useState([]);
  const [servicemodal, setServicemodal] = useState(false);
  const [approvedStatus, setapprovedStatus] = useState([]);
  const sellerApprovalId = reactLocalStorage.getObject("seller_approval_id");
  const [loading, setLoading] = useState(false);
  const userdata = reactLocalStorage.getObject("userData");
  const [subsPopUp, setSubsPopUp] = useState(false);
  const [subsDetails, setSubdetails] = useState({})
  const [descriptionShow, setDescriptionShow] = useState(false);
  const [desDetail, setDesDetail] = useState("");
  const [isClicked, setClicked] = useState(false);
  const [isSubscribe, setIsSubscribe] = useState(false);

  useEffect(() => {
    console.log(reactLocalStorage.getObject("userData"));
    fetchSublist();
    fetchStatus();
    window.scrollTo(0, 0);
  }, []);

  const callBackService = (val) => {
    setServicemodal(val);
    fetchSublist();
  };

  const fetchStatus = async () => {
    let obj = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData(
      "seller-approval-status/" + reactLocalStorage.getObject("userData")._id,
      "GET"
    );
    console.log("seller-approval-status", result);
    if (result && result.status) {
      setapprovedStatus(result.data);
      obj.type = result.data.type;
      reactLocalStorage.setObject("userData", obj);
    } else {
    }
  };

  const fetchSublist = async () => {
    console.log("idd", userdata._id);
    setLoading(true);
    let data = {
      currency: userdata.currency,
    };
    let result = await HttpClient.requestData(
      "listSubs/" + userdata._id,
      "GET",
      data
    );
    console.log("subscriptionList", result);
    if (result && result.status) {
      let data = result.data;
      console.log("dataaaa", data);

      data.forEach(sub => {
        if (sub.speakers.length > 0) {
          setIsSubscribe(true);
        }
        else {
        }
      });

      setSubscription(data);
      setTimeout(() => {
        setLoading(false);
      }, 1);
      console.log("subscription", subscription);
    } else {
    }
  };

  const buySubscription = async (item) => {
    let sendData = {
      userid: userdata._id,
      subscr_id: item._id,
      seller_comission: item.seller_comission,
      price: item.price,
      no_of_listing: item.no_of_listing,
      currency: userdata.currency,
    };
    console.log("object", sendData);

    let result = await HttpClient.requestData(
      "subscription-purchase",
      "POST",
      sendData
    );
    console.log("result", result);
    if (result && result.status) {
      reactLocalStorage.setObject("userData", result.userData);
      const type = reactLocalStorage.getObject("userData").type;
      if (type === "Seller") {
        reactLocalStorage.setObject("sellerType", { status: true });
      } else if (type === "User") {
        reactLocalStorage.setObject("sellerType", { status: false });
      }
      toast.success(result.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      fetchSublist();

      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 3000);
    } else {
      toast.error(result.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const unSubscribe = async (item) => {
    // console.log("object", sendData);
    let result = await HttpClient.requestData(
      "subscription/" + userdata._id,
      "PUT"
    );
    console.log("result", result);
    if (result && result.status) {
      reactLocalStorage.setObject("userData", result.userData);
      const type = reactLocalStorage.getObject("userData").type;
      if (type === "Seller") {
        reactLocalStorage.setObject("sellerType", { status: true });
      } else if (type === "User") {
        reactLocalStorage.setObject("sellerType", { status: false });
      }
      history.push("/dashboard");
      // location.reload();
      // window.location.href=

      toast.warning(result.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      fetchSublist();

      setTimeout(() => {
        // window.location.href = "/seller";
      }, 3000);
    } else {
      toast.error(result.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleUnsubscribe = (subs) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Unsubscrubed!',
          'You have unsubscribed Successfully ',
          'success'
        );
        unSubscribe(subs);
      }
    })
  }


  return (
    <>
      <div className="page-content">
        <ToastContainer />
        subs page
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {subscription.map((subs, index) => {
            return (
              <>
                <div className="-card-bg-xl">
                  <div className="-card-title-">
                    <h3 className="-anim-scale-down">
                      {subs.name[0].toUpperCase() + subs.name.substring(1)}{" "}
                      Subscription
                    </h3>
                    <h5 className={`-title-anim-fade- ${subs.name}`}>
                      {" "}
                      {/* $ */}
                      {userdata.currency == "INR"
                        ? "₹"
                        : userdata.currency == "CAD"
                          ? "$"
                          : userdata.currency == "USD"
                            ? "$"
                            : userdata.currency == "GBP"
                              ? "£"
                              : userdata.currency == "EUR"
                                ? "€"
                                : "$"}
                      {subs.price}
                    </h5>
                    <div
                      onClick={() => {
                        console.log("clicked");
                        setDesDetail(subs.description);
                        setDescriptionShow(true);
                      }}
                      className="-description-"
                    >
                      <p className="-desc-text-">
                        {" "}
                        {subs.description.slice(0, 30)}
                        ...ReadMore
                      </p>
                    </div>
                  </div>
                  {subs.speakers.length > 0 ? (
                    <div
                      onClick={(subs) =>
                        handleUnsubscribe(subs)
                      }
                      disabled={
                        approvedStatus.seller_approval == false ? true : false
                      }
                      id="-btn-"
                      className="-btn- -btn-clicked-"
                    >
                      Subscribed
                    </div>
                  ) : (
                    <div
                      // onClick={handleClickn}
                      onClick={() => {
                        setSubsPopUp(true)
                        setSubdetails(subs)
                        // buySubscription(subs);
                        // setServicemodal(true);
                      }}
                      disabled={
                        approvedStatus.seller_approval == false ? true : false
                      }
                      id="-btn-"
                      className={`-btn- ${isSubscribe && 'dis-able'}`}
                    >
                      Subscribe
                    </div>
                  )}
                  {/* <div
                    // onClick={handleClickn}
                    onClick={() => {
                      buySubscription(subs);
                      // setServicemodal(true);
                    }}
                    disabled={
                      approvedStatus.seller_approval == false ? true : false
                    }
                    id="-btn-"
                    // className={`-btn- ${subs.speakers.length > 0 ? "-btn-clicked-" : ""}`}
                  >
                    {subs.speakers.length > 0 ? "subscribed" : "subscribe"}
                  </div> */}
                  <div
                    className={
                      descriptionShow
                        ? "modal single_modal show fade"
                        : "modal show fade"
                    }
                    tabIndex="-1"
                    style={{ display: descriptionShow ? "block" : "none" }}
                  >
                    <DescriptionPopup
                      description={desDetail}
                      setShow={setDescriptionShow}
                    />
                  </div>
                  <div
                    className={
                      subsPopUp
                        ? "modal single_modal show fade"
                        : "modal show fade"
                    }
                    tabIndex="-1"
                    style={{ display: subsPopUp ? "block" : "none" }}
                  >
                    <SubscriptionPopUp
                      item={subsDetails}
                      setShow={setSubsPopUp}
                    />
                  </div>
                </div>
              </>
              // <MyCard cardType={subs.name} price={subs.price}  desc={subs.description}/>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Index;
