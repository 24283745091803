import React, { Component } from 'react';
import { Row, Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";

//Import Vector Map Component
import Vector from "./Vectormap";

class RevenueByLocations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false
        }
    }

    render() {
        return (
            <React.Fragment>
                <Col lg={4}>
                    <Card>
                        <CardBody>


                            {/* <h4 className="card-title mb-4">Revenue by Locations</h4> */}

                            <div>
                                <div className="text-center">
                                    <h6>Accept Booking</h6>
                                    <h4>{this.props.accept}</h4>

                                </div>



                            </div>

                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default RevenueByLocations;