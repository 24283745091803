import React, { Component } from 'react';
import { Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Table } from "reactstrap";
import { Link } from "react-router-dom";

//Import Images
import img1 from "../../assets/images/companies/img-1.png";
import img2 from "../../assets/images/companies/img-2.png";
import img3 from "../../assets/images/companies/img-3.png";

class Sources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        }
    }

    render() {
        return (
            <React.Fragment>
                <Col lg={4}>
                    <Card>
                        <CardBody>


                            {/* <h4 className="card-title mb-3">Sources</h4> */}

                            <div>
                                <div className="text-center">
                                    <h6 >Total Booking</h6>
                                    <h4>{this.props.total}</h4>

                                </div>



                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default Sources;