import React, { Component } from "react";

import {
  Row,
  Col,
  Input,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { checkLogin, apiError } from "../../store/actions";

// import images
import logodark from "../../assets/images/image1.png";

import HttpClient from "./../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import Loader from "../../components/loader";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: false,
      msg: "",
      success: false,
      loading: false,
      seller_token: this.props.match.params.token,
    };
  }

  // handleSubmit=async(event, values) => {
  //   let data = { email: this.state.email, password: this.state.password };
  //   console.log("data", data);
  //   let result = await HttpClient.requestData("login", "POST", data);
  //   console.log("login", result);
  //   if (result && result.status) {
  //     // reactLocalStorage.setObject("userData", result.data);

  //     this.setState({ success: true, msg: "Login Successfull." });
  //     setTimeout(() => {
  //       this.setState({ success: false });
  //       this.props.history.push("/dashboard");
  //     }, 100);
  //   } else {
  //     if (typeof result.error == "string") {
  //       this.setState({ error: true, msg: result.error });
  //     } else {
  //       this.setState({ error: true, msg: result.error[0].msg });
  //     }
  //   }
  //   // this.props.checkLogin(values, this.props.history);
  // }

  // componentWillMount() {
  //   let search = window.location.search;
  //   let params = new URLSearchParams(search);
  //   let foo = params.get("token");
  //   console.log("eee",foo);
  // }

  componentDidMount() {
    // this.props.apiError("");
    // document.body.classList.add("auth-body-bg");
    // let user = reactLocalStorage.getObject("userData");
    // // console.log("params",this.state.seller_token);
    // if (user != null && Object.keys(user).length > 0) {
    //   // return true;
    //   let search = window.location.search;
    //   let params = new URLSearchParams(search);
    //   let foo = params.get("seller_token");
    //   console.log("eee", foo);
    //   window.location.href = "/dashboard";
    // };

    console.log("sss", this.state.seller_token);
    this.handleAutoLogin();
  }

  handleAutoLogin = async (event, values) => {
    // let data = {
    //   token: this.state.seller_token
    // }
    let result = await HttpClient.requestData(
      "seller-portal/" + this.state.seller_token,
      "GET"
    );
    console.log("login", result);
    // return false;
    if (result && result.status) {
      reactLocalStorage.setObject("sellerType", { status: true });
      reactLocalStorage.setObject("userData", result.data);
      // window.location.href = "/dashboard";
      //subscription-plan
      this.props.history.push("/dashboard");
      
    } else {
      reactLocalStorage.setObject("userData", result.data);
      reactLocalStorage.setObject("sellerType", { status: false });
      this.props.history.push("/dashboard");
    }
    // this.props.checkLogin(values, this.props.history);
  };

  // componentWillUnmount() {
  //   document.body.classList.remove("auth-body-bg");
  // }

  render() {
    return (
      <React.Fragment>
        {/* <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white"></i>
          </Link>
        </div> */}

        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col lg={4}>
                <div
                  className="authentication-page-content p-4 d-flex align-items-center min-vh-100"
                  style={{ backgroundColor: "#000" }}
                >
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          {this.state.loading ? (
                            <Loader />
                          ) : (
                            <div className="text-center">
                              <div>
                                <Link to="#" className="logo">
                                  <h4>My Spiritual Seller Admin</h4>
                                </Link>
                              </div>

                              <h4
                                className="font-size-18 mt-4"
                                style={{ color: "#fff" }}
                              >
                                Welcome Back !
                              </h4>
                              <p className="text-muted">
                                Sign in to continue to My-Spiritual Seller
                                Admin.
                              </p>
                            </div>
                          )}

                          {/* {this.state.error ? (
                            <Alert color="danger">{this.state.msg}</Alert>
                          ) : null}

                          {this.state.success ? (
                            <Alert color="success">{this.state.msg}</Alert>
                          ) : null} */}

                          <div className="p-2 mt-5">
                            {/* <AvForm
                              className="form-horizontal"
                              // onValidSubmit={this.handleSubmit}
                            >
                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="username">Username</Label>
                                <AvField
                                  name="username"
                                  value={this.state.email}
                                  type="text"
                                  className="form-control"
                                  id="username"
                                  validate={{ email: true, required: true }}
                                  placeholder="Enter username"
                                  onChange={(val) => {
                                    this.setState({ email: val.target.value });
                                  }}
                                />
                              </FormGroup>

                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="userpassword">Password</Label>
                                <AvField
                                  name="password"
                                  value={this.state.password}
                                  type="password"
                                  className="form-control"
                                  id="userpassword"
                                  placeholder="Enter password"
                                  onChange={(val) => {
                                    this.setState({
                                      password: val.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>

                              <div className="custom-control custom-checkbox">
                                <Input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customControlInline"
                                />
                                <Label
                                  className="custom-control-label"
                                  htmlFor="customControlInline"
                                >
                                  Remember me
                                </Label>
                              </div>

                              <div className="mt-4 text-center">
                                <Button
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Log In
                                </Button>
                              </div>

                              <div className="mt-4 text-center">
                                <Link
                                  to="/forgot-password"
                                  className="text-muted"
                                >
                                  <i className="mdi mdi-lock mr-1"></i> Forgot
                                  your password?
                                </Link>
                              </div>
                            </AvForm> */}
                          </div>

                          {/* <div className="mt-5 text-center">
                            <p>
                              Don't have an A?{" "}
                              <Link
                                to="#"
                                className="font-weight-medium text-primary"
                              >
                                {" "}
                                Register{" "}
                              </Link>{" "}
                            </p>
                            <p>© 2020 One&Done Plus.</p>
                          </div> */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="authentication-bg">
                  {/* <div className="bg-overlay"></div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(
  connect(mapStatetoProps, { checkLogin, apiError })(Login)
);
