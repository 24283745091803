import React, { Component } from "react";
import { CSVLink } from "react-csv";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import Modal from "./cancelmodal";
//import ModalPendingBooking from "./ModalPendingBooking";

const header = [
  { label: "Sl.", key: "sl" },
  { label: "Order Id", key: "order_id" },
  { label: "Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Slot Day", key: "day" },
  { label: "Slot Time(From)", key: "fromtime" },
  { label: "Slot Time(to)", key: "totime" },
  { label: "Date of Booking", key: "bookdate" },
  { label: "Date of Appoinment", key: "date_of_appointment" },
  { label: "Category Name", key: "cat_name" },
  { label: "Service Name", key: "service" },
  { label: "Price", key: "price" },
  { label: "Buyer Currency", key: "currency" },
];
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Cancel Booking List", link: "#" },
        { title: "Manage Cancel Booking List", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},

      image_select: false,
      img_url: "",
      isOpenLike: false,
      isOpenLike1: false,
      bookingid: "",
      modaluserid: "",
      cancelBooking: false,
      pincode: "",
    };
    // this.handleDefault = this.handleDefault.bind(this);
  }

  componentDidMount = async () => {
    this.checkUser();
  };

  callbackLike = async () => {
    this.state.isOpenLike = false;
    this.setState({});

    console.log(this.state.isOpenLike);
  };
  //   callbackLike1 = async () => {
  //     this.state.isOpenLike1 = false;
  //     this.setState({});

  //     console.log(this.state.isOpenLike1);
  //   };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    console.log("user", user);
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user._id,
      });
      this.fetchData(user._id);
    }
  };

  fetchData = async (id) => {
    console.log("id====", id);
    let userData = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData(
      "seller-service/rejected-bookings",
      "GET"
    );
    console.log("result", result);
    if (result && result.status) {
      // this.setState({
      //   result: result.data,
      // })
      let cur_data = [];
      let tr = result.data.filter((it) => it.booking_accept == false);
      if (tr.length > 0) {
        this.setState({ result: tr });
      }
      console.log("***cancel", this.state.result);
      let data = [];
      let sl = 1;

      result.data.forEach((element, index) => {
        let i =
          element.shopservice_data.length > 0 &&
          element.shopservice_data[0].image.findIndex(
            (element) => element.ismain == true
          );
        let image =
          element.shopservice_data.length > 0
            ? element.shopservice_data[0].image
            : "";
        let fname =
          element.user_data.length > 0 ? element.user_data[0].firstName : "";
        let lname =
          element.user_data.length > 0 ? element.user_data[0].lastName : "";
        let a =
          i > -1
            ? element.shopservice_data.length > 0 &&
              element.shopservice_data[0].image[i].preview
            : null;

        let rows = {
          sl: sl,
          name: fname + " " + lname,
          email: element.user_data.length > 0 ? element.user_data[0].email : "",
          day: element.day_name_of_booking
            ? element.day_name_of_booking
            : "No Day",
          fromtime: element.from,
          totime: element.to,
          bookdate: moment(element.booking_date).format("DD-MM-YYYY"),

          cat_name:
            element.shopservice_data.length > 0
              ? element.shopservice_data[0].category_name
              : "",
          service:
            element.shopservice_data.length > 0
              ? element.shopservice_data[0].name
              : "",
          price:
            element.shopservice_data.length > 0
              ? (userData.currency == "INR"
                  ? "₹"
                  : userData.currency == "CAD"
                  ? "$"
                  : userData.currency == "USD"
                  ? "$"
                  : userData.currency == "	GBP"
                  ? "£"
                  : "€") + element.cart_data[0].price
              : "",
              discount_amount:element.cart_data[0]?.discount_amount??"0",

          order_id: element.cart_data[0] ? element.cart_data[0].order_id : "",
          date_of_appointment: moment(element.date_of_booking).format(
            "DD-MM-YYYY"
          ),
          currency: element.cart_data[0] ? element.cart_data[0].currency : "",
          img: (
            <div>
              {i > -1 ? (
                <img
                  src={HttpClient.IMG_URL + a}
                  alt="images"
                  className="rounded avatar-md card-img"
                />
              ) : (
                <img
                  src={
                    HttpClient.IMG_URL +
                    element.shopservice_data[0].image[0].preview
                  }
                  alt="images"
                  className="rounded avatar-md card-img"
                />
              )}
            </div>
          ),

          View: (
            <>
              <button
                className="btn btn-success mr-2"
                onClick={() => {
                  this.setState({
                    isOpenLike: true,
                    modaluserid: element._id,
                  });
                }}
              >
                View
              </button>
            </>
          ),

          assign: element.rejected_by == "seller" ?  (
            <button className={"btn btn-danger mr-2"}>Cancelled by Seller</button>
          ) : (
            <button className={"btn btn-danger mr-2"}>Cancelled by Buyer</button>
          ),
        };
        sl++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Order Id",
          field: "order_id",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Booked By",
        //   field: "booked_by",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 270,
        },
        {
          label: "Slot Day",
          field: "day",
          sort: "asc",
          width: 270,
        },
        {
          label: "Slot Time(From)",
          field: "fromtime",
          sort: "asc",
          width: 270,
        },
        {
          label: "Slot Time(to)",
          field: "totime",
          sort: "asc",
          width: 270,
        },
        {
          label: "Date of Booking",
          field: "bookdate",
          sort: "asc",
          width: 270,
        },
        {
          label: "Date of Appoinment",
          field: "date_of_appointment",
          sort: "asc",
          width: 270,
        },
        {
          label: "Category Name",
          field: "cat_name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Service Name",
          field: "service",
          sort: "asc",
          width: 100,
        },
        {
          label: "Price",
          field: "price",
          sort: "asc",
          width: 100,
        },
        {
          label: "Discount",
          field: "discount_amount",
          sort: "asc",
          width: 100,
        },
        {
          label: "Buyer Currency",
          field: "currency",
          sort: "asc",
          width: 100,
        },
        {
          label: "Image",
          field: "img",
          sort: "asc",
          width: 100,
        },

        {
          label: "Booking Status",
          field: "assign",
          sort: "asc",
          width: 100,
        },

        {
          label: "View",
          field: "View",
          sort: "asc",
          width: 100,
        },
        // {
        //   label: "Action",
        //   field: "action",
        //   sort: "asc",
        //   width: 100,
        // },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col lg={12}>
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.message}
                  </UncontrolledAlert>
                ) : null}
              </Col>
            </Row>

            <Row>
              <Col xl={12}>
                {this.state.data.length > 0 ? (
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"CancelBookingList.csv"}
                    className="btn btn-success"
                  >
                    Export Report in CSV
                  </CSVLink>
                ) : null}
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>{" "}
          {this.state.isOpenLike ? (
            <Modal
              isOpen={this.state.isOpenLike}
              userid={this.state.userId}
              modaluserid={this.state.modaluserid}
              closeCallback={this.callbackLike}
            />
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
