import React, { Component } from "react";
import { CSVLink } from "react-csv";
import Details from "./details";
import ChatWithBuyer from "../OderHistory/chatwithbuyer";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
// import EcomPreview from "./E-ComPreview/index";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import index from "../ChooseDesign";
import ChatWithPersonel from "../OderHistory/chatwithpersonel";
import "./chatlist.css";
import Firebase from "../chats/firebase";
import { result } from "lodash";

const header = [
  { label: "Sl.", key: "sl" },
  { label: "Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Order Id", key: "orderid" },
  { label: "Book Date", key: "bookdate" },
  { label: "Category Name", key: "cat_name" },
  { label: "Service Name", key: "service" },
  { label: "Total Price", key: "total_price" },
  { label: "Product Currency", key: "currency" },
  { label: "Comission for Admin", key: "admin_com" },
];

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Order History", link: "#" },
        { title: "Show Order History", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      //   this page states
      category: [],
      categoryAll: [],
      subCategory: [],
      subCategoryAll: [],
      subServiceAll: [],
      eachservicedetails: {},
      cat_id: "",
      pruduct_type: "",
      sub_cat_id: "",
      sub_srvc_id: "",
      market_price: "",
      selling_price: "",

      // selectedFiles: [],
      images: "",
      uploaded_images: [],

      sell_price: "",
      purchase_price: "",
      discount: "",
      availableSize: [],
      availableColor: [],
      hashtag: "",
      personalisation: "",
      details: "",
      price: "",
      allService: [],
      shopService: [],
      allService_id: "",
      allShopService_id: "",
      to_time: "",
      from_time: "",
      available_duration: 0,
      slot_duration_id: "",
      orderHistory: [],
      optionGroupSize: [{ value: "Red", code: "" }],
      selectedMultiSize: [],

      showSelectColor: false,
      showSelect: false,
      colorRgb: "#4667CC",
      simple_color1: false,
      color: [{ code: "#4667CC", active: false }],
      description: "",
      selectcategory: "",
      selectshopserviceid: "",
      edit_id: "",
      weekDay: [
        { label: "Monday", value: "Monday" },
        { label: "Tuesday", value: "Tuesday" },
        { label: "Wednesday", value: "Wednesday" },
        { label: "Thursday", value: "Thursday" },
        { label: "Friday", value: "Friday" },
        { label: "Saturday", value: "Saturday" },
        { label: "Sunday", value: "Sunday" },
      ],
      slot_duration: [
        { label: "10", value: "10" },
        { label: "15", value: "15" },
        { label: "30", value: "30" },
      ],
      language: [{ label: "en", value: "en" }],
      lang: "",
      slot_time: "",
      selectedWeek: "",
      selectWeekName: "",
      oderId: [],
      ismodalopen: false,
      ismodalopen1: false,
      orderid: "",
      firebaseData: [],
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    // this.checkUser();
    // this.fetchService();
    //this.fetchData1();
    this.fetchData1();
  };

  onCloseCallBack = async (val) => {
    this.setState({ ismodalopen: val });
  };

  fetchData1 = async () => {
    let userData = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData(
      "seller_account/service-order-history/" + userData._id,
      "GET"
    );
    console.log("getOrderHistory", result);

    if (result && result.status) {
      let arr = [];
      result.data.forEach((element, index) => {
        if(element.order_id){
          Firebase.database()
          .ref()
          .child("PersonelSellerchat")
          .child(element?.order_id)

          .on("value", (snapshot) => {
            if (snapshot.exists()) {
              console.log("snapshot", snapshot.val());

              let dom = {
                id: snapshot.val().order_id,
              };

              arr.push(dom);
              console.log("arr", arr);

              this.setState({
                firebaseData: arr,
              });
            }
            console.log("Array 2", this.state.firebaseData);
            this.fetchData(arr);
          });
        }
       
      });
    }
  };

  fetchData = async (arrfirebase) => {
    console.log("hii");
    console.log(this.state.selectshopserviceid);
    let userData = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData(
      "seller_account/service-order-history/" + userData._id,
      "GET"
    );
    console.log("getOrderHistory", result);
    if (result && result.status) {
      let data = [];
      let sl = 1;
      this.setState({ result: result.data });
      result.data.forEach((element, index) => {
        // console.log("element", element);
        let j = arrfirebase.findIndex((ele) => ele.id == element.order_id);
        // console.log("valueof j ", j);
        let i = element.image.findIndex((element) => element.ismain == true);
        let image =
          element.service_data.length > 0 ? element.service_data[0].image : "";
        let fname =
          element.buyer_data.length > 0 ? element.buyer_data[0].firstname : "";
        let lname =
          element.buyer_data.length > 0 ? element.buyer_data[0].lastname : "";
        let comission =
          element.admin_commission.length > 0
            ? element.admin_commission[0].percentage
            : 0;
        let rows = {
          sl: sl,
          // name: fname + " " + lname,
          // email:
          //   element.buyer_data.length > 0 ? element.buyer_data[0].email : "",
          // orderid: element.order_id ? element.order_id : "",
          name: element.checkout_data.length>0?element.checkout_data[0].firstname + " " + element.checkout_data[0].lastname:"",

          email:
          element.checkout_data.length>0?element.checkout_data[0].email:"" ,
          admin_com: (element.price * comission) / 100,
          total_price:
            (userData.currency == "INR"
              ? "₹"
              : userData.currency == "CAD"
              ? "$"
              : userData.currency == "USD"
              ? "$"
              : userData.currency == "	GBP"
              ? "£"
              : "€") + element.price,
          currency: element.currency ? element.currency : "",
          price: element.price - (element.price * comission) / 100,
          // price:element?.checkout_data[0]?.total??"0",
          bookdate: moment(element.date_of_booking).format("DD-MM-YYYY"),
          discount_amount:element?.discount_amount??"0",

          cat_name:
            element.service_data.length > 0
              ? element.service_data[0].category_name
              : "",
          service:
            element.service_data.length > 0 ? element.service_data[0].name : "",
          complete_order: element.seller_confirmed ? (
            <button
              title="Complete Order"
              // className="btn btn-primary"
              className={
                element.completestatus == "complete"
                  ? "btn btn-success"
                  : "btn btn-primary"
              }
              onClick={() => {
                this.completeBooking(element, index);
              }}
            >
              {element.completestatus == "complete"
                ? "Completed"
                : "Complete Order"}
              {/* Complete Order */}
            </button>
          ) : null,
          order_status: (
            <div>
              {element.seller_confirmed == true ? (
                <button
                  title="Order Accepted"
                  // className="btn btn-primary"
                  className="btn btn-success"
                >
                  Order Accepted
                </button>
              ) : element.seller_reject == true ? (
                <button
                  title="Order Cancelled"
                  // className="btn btn-primary"
                  className="btn btn-danger"
                >
                  Order Cancelled
                </button>
              ) : element.order_status == "cancelled" ? (
                <button
                  title="Cancelled by Buyer"
                  // className="btn btn-primary"
                  className="btn btn-danger"
                >
                  Cancelled by Buyer
                </button>
              ) : (
                <button
                  title="Order Pending"
                  // className="btn btn-primary"
                  className="btn btn-warning"
                >
                  Order Pending
                </button>
              )}
              &nbsp;
              {element.refund_request == "Yes" ? (
                <button
                  title="Refund Claim"
                  // className="btn btn-primary"
                  className="btn btn-primary"
                >
                  Refund Claim
                </button>
              ) : null}
            </div>
          ),
          img: (
            <div>
              {i > -1 ? (
                <img
                  src={HttpClient.IMG_URL + element.image[i].preview}
                  alt="images"
                  className="rounded avatar-md card-img"
                />
              ) : (
                <img
                  src={HttpClient.IMG_URL + element.image[0].preview}
                  alt="images"
                  className="rounded avatar-md card-img"
                />
              )}
            </div>
          ),
          details: (
            <button
              title="Details"
              className="btn btn-primary"
              onClick={() => {
                this.setState({
                  oderId: element,
                  ismodalopen: true,
                });
              }}
            >
              View Details
            </button>
          ),
          reply: (
            <div>
              {j > -1 ? (
                <Button
                  color="info"
                  type="button"
                  className="btn-sm"
                  onClick={() => {
                    this.state.ismodalopen1 = true;
                    this.state.orderid = element.order_id;
                    // this.setState({});
                    this.setState({eachservicedetails: element})
                  }}
                >
                  Chat with Admin
                </Button>
              ) : null}
            </div>
          ),

          chatwithbuyer: (
            <Button
              color="secondary"
              type="button"
              className="btn-sm"
              onClick={() => {
                this.state.isOpenmodalbuyer = true;
                this.state.orderid = element.order_id;
                this.state.name = fname + " " + lname;
                this.state.servid =
                  element.service_data.length > 0
                    ? element.service_data[0]._id
                    : "";
                //  this.setState({});
                this.setState({eachservicedetails: element})
              }}
            >
              Chat with Buyer
            </Button>
          ),

          withdraw: (
            <button
              title="Details"
              className={
                element.earning_data.length > 0 &&
                element.earning_data[0].receipt_status == "Requested"
                  ? "btn btn-warning"
                  : element.earning_data.length > 0 &&
                    element.earning_data[0].receipt_status == "Approved"
                  ? "btn btn-danger"
                  : "btn btn-success"
              }
              onClick={() => {
                this.withdraw(
                  element,
                  element.price - (element.price * comission) / 100,
                  (element.price * comission) / 100
                );
              }}
            >
              {element.earning_data.length > 0 &&
              element.earning_data[0].receipt_status == "Requested"
                ? "Requested for WithDraw"
                : element.earning_data.length > 0 &&
                  element.earning_data[0].receipt_status == "Approved"
                ? "Approved for Withdrawl"
                : "Withdraw"}
            </button>
          ),
        };
        sl++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
    //
  };

  completeBooking = async (item, index) => {
    console.log("completeBooking", item, index);

    let result = await HttpClient.requestData(
      "seller-service/complete-booking/" + item._id,
      "PUT"
    );

    console.log("completeBooking---", result);
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "Order Completed Successfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 2000);
      this.fetchData1();
      // window.location.href = "/accept-booking";
    } else {
      this.setState({
        alert: true,
        message: result.message,
        type: "danger",
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 2000);
    }
  };

  withdraw = async (item, seller_com, admin_com) => {
    console.log("item", item);

    let sendData = {
      seller_id: item.seller_id,
      serv_id: item.service_id,
      order_id: item.order_id,
      total_earning: item.price,
      seller_earning: seller_com,
      commission: admin_com,
      images: item.image,
    };
    let result = await HttpClient.requestData(
      "seller_account/withdraw-earnings",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.setState({
        alert: true,
        message: result.message,
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);

      this.fetchData();
    }
  };

  Callbackmsg = async (val) => {
    this.state.ismodalopen1 = false;
    this.setState({});
  };

  Callbackmsg1 = async (val) => {
    console.log(val);
    this.state.isOpenmodalbuyer = false;
    this.setState({});
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        // {
        //   label: "Booked By",
        //   field: "booked_by",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 270,
        },
        {
          label: "Order Id",
          field: "orderid",
          sort: "asc",
          width: 270,
        },

        {
          label: "Book Date",
          field: "bookdate",
          sort: "asc",
          width: 270,
        },
        {
          label: "Category Name",
          field: "cat_name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Service Name",
          field: "service",
          sort: "asc",
          width: 100,
        },
        {
          label: "Total Price",
          field: "total_price",
          sort: "asc",
          width: 100,
        },
        {
          label: "Discount",
          field: "discount_amount",
          sort: "asc",
          width: 100,
        },
        // {
        //   label: "Price",
        //   field: "price",
        //   sort: "asc",
        //   width: 100,
        // },
        {
          label: "Product Currency",
          field: "currency",
          sort: "asc",
          width: 100,
        },
        {
          label: "Comission for Admin",
          field: "admin_com",
          sort: "asc",
          width: 100,
        },
        {
          label: "Image",
          field: "img",
          sort: "asc",
          width: 100,
        },

        // {
        //   label: "Booking Status",
        //   field: "assign",
        //   sort: "asc",
        //   width: 100,
        // },
        {
          label: "Order Status",
          field: "order_status",
          sort: "asc",
          width: 280,
        },

        {
          label: "View Details",
          field: "details",
          sort: "asc",
          width: 270,
        },
        {
          label: "Chat with Personel",
          field: "reply",
          sort: "asc",
          width: 270,
        },
        {
          label: "Chat with Buyer",
          field: "chatwithbuyer",
          sort: "asc",
          width: 270,
        },
        {
          label: "Order Complete",
          field: "complete_order",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Action",
        //   field: "withdraw",
        //   sort: "asc",
        //   width: 100,
        // },
      ],
      rows: this.state.data,
    };
    return (
      
      <React.Fragment>
        <div className="page-content">
        {/* {console.log("orderHistory===",)} */}
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {this.state.alert ? (
              <UncontrolledAlert
                color={this.state.type}
                className="alert-dismissible fade show"
                role="alert"
              >
                {this.state.type == "warning" ? (
                  <i className="mdi mdi-alert-outline mr-2"></i>
                ) : this.state.type == "success" ? (
                  <i className="mdi mdi-check-all mr-2"></i>
                ) : this.state.type == "danger" ? (
                  <i className="mdi mdi-block-helper mr-2"></i>
                ) : null}
                {this.state.message}
              </UncontrolledAlert>
            ) : null}

            <Row>
              <Col xl={12}>
                {this.state.data.length > 0 ? (
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"OrderHistoryList.csv"}
                    className="btn btn-success"
                  >
                    Export Report in CSV
                  </CSVLink>
                ) : null}
                <div className="card p-3">
                  {this.state.ismodalopen ? (
                    <Details
                      status={true}
                      onCloseCallBack={this.onCloseCallBack}
                      data={this.state.oderId}
                    />
                  ) : null}
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    disableRetreatAfterSorting={true}
                    onPageChange={()=>{window.scrollTo(0,0)}}
                  />
                </div>
                {this.state.ismodalopen1 ? (
                  <ChatWithPersonel
                    status={true}
                    // orderdetails={sentorderdetails}
                    orderid={this.state.orderid}
                    onCloseCallBack={this.Callbackmsg}
                    image=""
                  />
                ) : null}
                {this.state.isOpenmodalbuyer ? (
                  <ChatWithBuyer
                    status={true}
                    orderdetails={this.state.eachservicedetails}
                    name={this.state.name}
                    orderid={this.state.orderid}
                    onCloseCallBack1={this.Callbackmsg1}
                    image=""
                    servid={this.state.servid}
                  />
                ) : null}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
