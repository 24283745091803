import React from 'react'

function DescriptionPopup({setShow,description}) {
    const rating = true
    console.log(description,'description')
  return (
      
    <div className="Rating">
    <div className="modal-dialog">
      {/* <ToastContainer /> */}
      <div className="modal-content pt-5 px-5 pb-4">
        <div className="">
          <span></span>
          <button
            type="button"
            className="close Single_close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShow(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="mb-3">
          {/* <h6 className=" mb-0" style={{ fontSize: "18px" }}>
            Rate this Service
          </h6> */}
          {/* <ReactStars
            count={5}
            onChange={ratingChanged}
            size={24}
            isHalf={true}
            emptyIcon={<i className="far fa-star"></i>}
            halfIcon={<i className="fa fa-star-half-alt"></i>}
            fullIcon={<i className="fa fa-star"></i>}
            activeColor="#ffd700"
          /> */}
        </div>
        <div className="mb-4">
          <h6 className=" mb-2" style={{ fontSize: "18px" }}>
          {description}          </h6>
          {/* <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="5"
            // onChange={(val) => {
            //   setcomment(val.target.value);
            // }}
          ></textarea> */}
        </div>
        {/* <div className=" pt-3 text-center mb-3">
          <button type="submit"
            // onClick={

            //   Submit
            // }
            className="btn  PrimaryBtn">Submit Review</button>
        </div> */}
      </div>
    </div>
  </div>  
  )
}

export default DescriptionPopup