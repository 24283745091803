import React, { useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
const Review = () => {
  const [breadcrumbItems, setBreadItems] = useState([
    { title: "My Spiritual", link: "#" },
    { title: "Review", link: "#" },
  ]);
  const loop = [1, 2, 3, 4];
  return (
    <React.Fragment>
      <div className="page-content  ">
        <div className="container-fluid">
          <Breadcrumbs title="Review" breadcrumbItems={breadcrumbItems} />
          <div className="row">
            <div className="col-md-8 mx-auto">
              {loop.map((item, index) => {
                return (
                  <div className="d-flex my-3" key={index}>
                    <img
                      src="https://image.pngaaa.com/569/2189569-middle.png"
                      alt="img"
                      style={{
                        width: "80px",
                        height: "50px",
                        borderRadius: "50%",
                      }}
                    />
                    <div>
                      <h6>Customer Name</h6>
                      <p>Review msg</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Review;
