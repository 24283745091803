import React, { useState, useEffect, useRef } from "react";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link } from "react-router-dom";
//import ReactJsAlert from "reactjs-alert";
import "./chatlist.css";
//import Firebase from "../../firebase-messaging-sw";
import Firebase from "../chats/firebase";

import { Row, Card, CardBody, Col, Input, Button } from "reactstrap";
import SimpleBar from "simplebar-react";
import img from "./pro.jpeg";

import moment from "moment";

const Refund = (props) => {
  console.log("bbbbb===", props.orderdetails);
  const messagesEndRef = useRef(null);
  const [modalStatus, setmodalStatus] = useState(
    props.status ? props.status : false
  );
  const [msglist, setmsglist] = useState([]);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [message, setmessage] = useState("");
  const [firstFile, setfirstFile] = useState("");
  const [filetype, setfiletype] = useState("");
  const userData = reactLocalStorage.getObject("userData");
  useEffect(() => {
    //getAccount();
    scrollToBottom();
    FetchMessageList();
    // Callrefund();
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  const SendMsg = async (url) => {
    console.log("hiii");

    let user_id = reactLocalStorage.getObject("userData")._id;
    let order_id = props.orderid;
    let date = Date.now();
    let date1 = moment(date).format("YYYY-MM-DD");
    let date2 = moment(date).format("HH:mm:ssZ");
    let msgTime = date1 + "T" + date2;

    Firebase.database()
      .ref()
      .child("SellerBuyerChat")
      .child(props.orderid)
      .child(props.servid)
      .child("msglist")
      .get()
      .then(
        (snapshot) => {
          if (snapshot.exists()) {
            let msgid = Firebase.database()
              .ref()
              .child("SellerBuyerChat")
              .child(props.orderid)
              .child(props.servid)
              .child("msglist")
              .push().key;

            //Use the Push() method to append data to a list in multiuser applications.
            // The Push() method generates a unique key every time
            console.log("new key", msgid);

            Firebase.database()
              .ref()
              .child("SellerBuyerChat")
              .child(props.orderid)
              .child(props.servid)
              .child("msglist")
              .child(msgid)
              .set(
                {
                  msgid: msgid,
                  msgby: "seller",
                  send_time: msgTime,
                  msgtext: message,
                  msgimg:
                    filetype == "image/jpeg" || filetype == "image/png"
                      ? url
                      : "",
                  msgvideo: filetype == "video/mp4" ? url : "",
                },

                (error) => {
                  if (error) {
                    alert("something went wrong");
                  } else {
                    console.log("127");

                    // this.state.message = "";
                    // this.setState({
                    //     firstFile: "",
                    //     selectedFiles: [],
                    // });

                    //FetchMessageList(this.state.user_id);
                    // Callrefund();
                  }
                }
              );
          } else {
            Firebase.database()
              .ref()
              .child("SellerBuyerChat")
              .child(props.orderid)
              .child(props.servid)

              .set({
                order_id: order_id,
                user_id: user_id,
                // userCount: this.state.userCount + 1, //notification
                // adminCount: 0, //notification
                lastMsgBy: "User",
                msglist: "",
                username: "",

                useremail: "",
              });
            let msgid = Firebase.database()
              .ref()
              .child("SellerBuyerChat")
              .child(props.orderid)
              .child(props.servid)
              .child("msglist")
              .push().key;
            console.log("msgid", msgid);

            Firebase.database()
              .ref()
              .child("SellerBuyerChat")
              .child(props.orderid)
              .child(props.servid)
              .child("msglist")
              .child(msgid)
              .set(
                {
                  msgid: msgid,
                  msgby: "seller",
                  send_time: msgTime,
                  msgtext: message,
                  msgimg:
                    filetype == "image/jpeg" || filetype == "image/png"
                      ? url
                      : "",
                  msgvideo: filetype == "video/mp4" ? url : "",
                },

                (error) => {
                  if (error) {
                    alert("something went wrong");
                  } else {
                    console.log("object");
                  }
                }
              );
          }
        },
        {
          onlyOnce: true,
        }
      );
    setmessage("");
    FetchMessageList(props.orderid);
  };
  const FetchMessageList = async () => {
    console.log("props.orderid", props.orderid);

    let arr = [];
    Firebase.database()
      .ref()
      .child("SellerBuyerChat")
      .child(props.orderid)
      .child(props.servid)
      .child("msglist")
      .on("value", (snapshot) => {
        const data = snapshot.val();

        console.log("data", data);
        if (snapshot.exists()) {
          snapshot.forEach((ele) => {
            console.log("ele", ele.val());
            let dom = {
              id: ele.val().msgid,
              msg: ele.val().msgtext,
              msgby: ele.val().msgby,
              msgimg: ele.val().msgimg,
              msgvideo: ele.val().msgvideo,
              msgtime: ele.val().send_time,
            };
            console.log(dom);
            arr.push(dom);
            console.log(arr);
          });

          // setmsglist(arr);
          const arr1 = getUniqueListBy(arr, "id");
          setmsglist(arr1);

          console.log(msglist);
        }
        // console.log("arr", arr);
        // setmsglist(arr);
        // console.log("jkk", msglist);
      });

    scrollToBottom();
  };

  return (
    <div
      className={modalStatus ? "modal show fade _modal_open" : "modal fade"}
      id="add-new-task-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="add-new-task-modalLabel"
      aria-hidden="true"
      style={{ display: modalStatus ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered replyBox modal-lg">
        <div className="modal-content">
          <Card className="mb-0">
            {/* chat head area */}
            <CardBody className="border-bottom chat_head py-2">
              <div className="user-chat-border">
                <Row>
                  <Col md={9} xs={9}>
                    <div className="d-flex">
                      <div className="my-auto mr-3">
                        <img
                          src={img}
                          className="img-fluid rounded-circle"
                          style={{ height: "30px", width: "30px" }}
                        />
                      </div>
                      <h5 className="font-size-15 my-auto mr-auto">
                        {props.name}
                      </h5>
                    </div>
                  </Col>

                  <Col md={3}>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                      onClick={() => props.onCloseCallBack1(false)}
                    >
                      ×
                    </button>
                  </Col>
                  <Col md={12}>
                    <div
                      className="card_txt"
                      style={{
                        overflowY: "scroll",
                        maxHeight: "100px",
                        padding: "10px 0",
                      }}
                    >
                      <p style={{ color: "black" }}>
                        Service Name: {props.orderdetails.service_name}
                      </p>
                      <p style={{ color: "black" }}>
                        Service Category Name:{" "}
                        {props.orderdetails.service_data[0].category_name}
                      </p>
                      <p style={{ color: "black" }}>
                        Booking Date:{" "}
                        {moment(props.orderdetails.booking_date).format(
                          "ddd [-] MMM DD YYYY"
                        )}
                      </p>
                      <p style={{ color: "black" }}>
                        Refund Status:{" "}
                        {props.orderdetails.refund_request
                          ? props.orderdetails.refund_request
                          : "No Data"}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
            {/* chat list body */}
            <CardBody>
              <div className="chat-widget">
                <div className="chat-conversation">
                  <SimpleBar style={{ maxHeight: "250px" }}>
                    <ul className="list-unstyled mb-0 pr-3">
                      {msglist.length > 0 &&
                        msglist.map((item, index) => {
                          console.log(item);
                          return (
                            <li
                              className={item.msgby === "user" ? "" : "right"}
                              key={index}
                            >
                              {item.msg != "" && item.msgimg != "" ? (
                                <div className="conversation-list">
                                  <div className="ctext-wrap">
                                    <div className="ctext-wrap-content py-1 px-2">
                                      <p className="mb-2">{item.msg}</p>

                                      <img
                                        data-dz-thumbnail
                                        src={item.msgimg}
                                        className="avatar-md rounded bg-light"
                                        alt="preview"
                                      />
                                      <p className="mb-2">
                                        {moment(item.msgtime).fromNow()}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : item.msg != "" && item.msgvideo != "" ? (
                                <div className="conversation-list">
                                  <div className="ctext-wrap">
                                    <div className="ctext-wrap-content py-1 px-2">
                                      <p className="mb-2">{item.msg}</p>

                                      <video
                                        // width="50%"
                                        hight={300}
                                        width={300}
                                        controls
                                        // className="rounded-lg"
                                      >
                                        <source
                                          src={item.msgvideo}
                                          type="video/mp4"
                                        />
                                      </video>
                                      <p className="mb-2">
                                        {moment(item.msgtime).fromNow()}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : item.msg == "" && item.msgimg != "" ? (
                                <div className="conversation-list">
                                  <div className="ctext-wrap">
                                    <div className="ctext-wrap-content py-1 px-2">
                                      <img
                                        data-dz-thumbnail
                                        src={item.msgimg}
                                        className="avatar-md rounded bg-light"
                                        alt="preview"
                                      />
                                      <p className="mb-2">
                                        {moment(item.msgtime).fromNow()}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : item.msg == "" && item.msgvideo != "" ? (
                                <div className="conversation-list">
                                  <div className="ctext-wrap">
                                    <div className="ctext-wrap-content py-1 px-2">
                                      <video
                                        // width="50%"
                                        controls
                                        hight={300}
                                        width={300}
                                        className="rounded-lg"
                                      >
                                        <source
                                          src={item.msgvideo}
                                          type="video/mp4"
                                        />
                                      </video>
                                      <p className="mb-2">
                                        {moment(item.msgtime).fromNow()}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="conversation-list">
                                  <div className="ctext-wrap">
                                    <div className="ctext-wrap-content py-1 px-2">
                                      <p className="mb-2">{item.msg}</p>
                                      <p className="mb-2">
                                        {moment(item.msgtime).fromNow()}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </li>
                          );
                        })}
                      <li ref={messagesEndRef}></li>
                    </ul>
                  </SimpleBar>
                </div>
              </div>
            </CardBody>
            {/* chat write area */}
            <div className="px-3 py-2 chat-input-section border-top">
              <Row>
                {/* upload image here */}
                <Col md={12}>
                  <div className="mb-2">
                    {selectedFiles.length > 0 &&
                      selectedFiles.map((item, index) => {
                        return (
                          <div className="upld_img">
                            {item.type == "image/jpeg" ? (
                              <img
                                data-dz-thumbnail
                                src={item.preview}
                                className="avatar-sm rounded bg-light"
                                alt="preview"
                              />
                            ) : (
                              <img
                                data-dz-thumbnail
                                src={img}
                                className="avatar-sm rounded bg-light"
                                alt="preview"
                              />
                            )}

                            <button
                              className="close border-0 bg-transparent p-0 img_remove"
                              onClick={() => {
                                this.setState({
                                  firstFile: "",
                                  selectedFiles: [],
                                });
                              }}
                            >
                              <i className="far fa-times-circle" />
                            </button>
                          </div>
                        );
                      })}
                    {/* {this.state.videoloading?
                        <img
                        src="https://tenor.com/view/loading-waiting-please-wait-gif-14579561"
                        />:null} */}
                  </div>
                </Col>
                {/* choose image */}
                {/* <Col md={1}>
                                    <label htmlFor="fileUpload" className="my_fileUpload">
                                        <i className="far fa-file-image"></i>
                                        <input
                                            type="file"
                                            id="fileUpload"
                                            className="fileUpload"
                                            onChange={handlefilePick}
                                        />
                                    </label>
                                </Col> */}
                {/* write message */}

                <Col md={9} className="px-lg-0">
                  <div>
                    <Input
                      type="text"
                      className="form-control rounded chat-input pl-3"
                      placeholder="Enter Message..."
                      onChange={(val) => setmessage(val.target.value)}
                      value={message}
                    />
                  </div>
                </Col>
                {message != "" && firstFile != "" ? (
                  <Col md={2} xs={{ size: "auto" }}>
                    <Button
                      color="primary"
                      type="button"
                      // onClick={uploadFile}
                      className="chat-send waves-effect waves-light font-size-12 w-100 btn-sm px-0"
                    >
                      <span className="d-none d-sm-inline-block mr-1">
                        Send
                      </span>{" "}
                      <i className="fas fa-paper-plane"></i>
                    </Button>
                  </Col>
                ) : message == "" && firstFile != "" ? (
                  <Col md={2} xs={{ size: "auto" }}>
                    <Button
                      color="primary"
                      type="button"
                      // onClick={uploadFile}
                      className="chat-send waves-effect waves-light font-size-12 w-100 btn-sm px-0"
                    >
                      <span className="d-none d-sm-inline-block mr-1">
                        Send
                      </span>{" "}
                      <i className="fas fa-paper-plane"></i>
                    </Button>
                  </Col>
                ) : message != "" && firstFile == "" ? (
                  <Col md={2} xs={{ size: "auto" }}>
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => SendMsg("")}
                      className="chat-send waves-effect waves-light font-size-12 w-100 btn-sm px-0"
                    >
                      <span className="d-none d-sm-inline-block mr-1">
                        Send
                      </span>{" "}
                      <i className="fas fa-paper-plane"></i>
                    </Button>
                  </Col>
                ) : (
                  <Col md={2} xs={{ size: "auto" }}>
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => {
                        alert("please enter message");
                      }}
                      className="chat-send waves-effect waves-light font-size-12 w-100 btn-sm px-0"
                    >
                      <span className="d-none d-sm-inline-block mr-1">
                        Send
                      </span>{" "}
                      <i className="fas fa-paper-plane"></i>
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default Refund;
