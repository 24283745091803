import React from "react";
import { Row, Col, Container, Button } from "reactstrap";
import { FaArrowCircleUp } from "react-icons/fa";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col sm={6}>{new Date().getFullYear()} © My Spiritual Seller</Col>
            <Button  type="button" 
              style={{ cursor: "pointer", backgroundColor: " #5664d2", marginLeft:"auto",height: "38px", width: "45px"}}
              onClick={scrollToTop}
            >
              <FaArrowCircleUp  
              style={{height: "25px", width: "25px"}}
              />

            </Button>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
