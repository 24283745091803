import React, { Component } from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";

class SalesAnalytics extends Component {
    state = {
        series: [42, 26, 15],
        options: {
            labels: ["Product A", "Product B", "Product C"],
            plotOptions: {
                pie: {
                    donut: {
                        size: '75%'
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false,
            },
            colors: ['#5664d2', '#1cbb8c', '#eeb902'],

        }
    }
    render() {
        return (
            <React.Fragment>
                <Col lg={4}>
                    <Card>
                        <CardBody>

                            {/* <h4 className="card-title mb-4">{this.props.name} Analytics</h4> */}

                            <div>
                                <div className="text-center">
                                    <h6>Reject Booking</h6>
                                    <h4>{this.props.reject}</h4>

                                </div>



                            </div>



                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default SalesAnalytics;