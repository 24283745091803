import React, { Component } from 'react';
import { Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

//Simple bar
import SimpleBar from "simplebar-react";

class RecentlyActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        }
    }
    componentDidMount() {
        console.log("hii", this.props.accept);
    }
    render() {
        return (
            <React.Fragment>
                <Col lg={4}>
                    <Card>
                        <CardBody>


                            {/* <h4 className="card-title mb-4">Recent Activity Feed</h4> */}
                            <div>
                                <div className="text-center">
                                    <h6>Pending Booking</h6>
                                    <h4>{this.props.pending}</h4>

                                </div>



                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default RecentlyActivity;