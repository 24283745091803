import React from 'react';
// import loader from "../Assets/images/loader.gif"
import loader from "../assets/images/users/loader.gif";


export default function 
() {
    return (
        <div className="main_loader">
            <img src={loader} alt="loader" className="loader_gif" />
        </div>
    )
}
