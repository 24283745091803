import React, { Component } from "react";
// MetisMenu
import MetisMenu from "metismenujs";
import { Redirect, withRouter } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
//i18n
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";


class SidebarContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isSubscribe:false
    };
  }

  fetchSublist = async () => {
   const userdata = reactLocalStorage.getObject("userData");
  //  console.log('userDataaaa',userdata);
    // console.log("idd", userdata._id);

    let data = {
      currency: userdata.currency,
    };
    let result = await HttpClient.requestData(
      "listSubs/" + userdata._id,
      "GET",
      data
    );
    console.log("sdebarsubscriptionList", result);
    if (result && result.status) {                                                                                                                    
      let data = result.data;
      console.log("dataSidebar", data);
      let subscribe = false                                                                                                                

      data.forEach(sub =>                                                                                                                    {
        if(sub.speakers.length>0){
          subscribe = true
        this.setState({isSubscribe:true})
          // console.log('isSubscribe',this.state.isSubscribe);
        }                                                                                                                                                                                
        else{
          // this.props.history.push = "/subscription-plan"
          // window.location.href="/subscription-plan"
        }
      });
      
      if(!subscribe && window.location.pathname !=="/subscription-plan"){

          window.location.href="/subscription-plan"

      }
      // console.log('isSubscribe',this.isSubscribe);
      
    } else {
    }
  };


  componentDidMount() {
    this.initMenu();
    this.fetchSublist();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  handleBack = () => {
    window.location.href = "http://localhost:3000/home";
  };

  render() {

    const sellerApproval = reactLocalStorage.getObject("sellerType").status;
    
    let path = window.location.pathname;
    console.log('pathSidebar',path);

    // if (!this.state.isSubscribe) {
    //   // window.location.href = "/subscription-plan"
    // }

    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t("Menu")}</li>

            <li>
              <a href="https://myspiritualconnect.com/">
                <i className="ri-arrow-left-line"></i>

                <span className="ml-1">{this.props.t("Back to Home")}</span>
              </a>
            </li>

            <li>
            <NavLink
                activeClassName="active"
                to="/dashboard"
                className={`waves-effect ${sellerApproval && this.state.isSubscribe ? "" : "dis-able"}`}
              >
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">{this.props.t("Dashboard")}</span>
              </NavLink>
            </li>

            <li>
              <Link
                className={`${sellerApproval && this.state.isSubscribe ? "" : "dis-able"}`}
                activeClassName="active"
                to={`${sellerApproval ? "/shopservice-category" : ""}`}>
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Shop Service")}</span>
              </Link>
            </li>

            <li>
              <Link
                activeClassName="active"
                to={`/subscription-plan`}>
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Subscription Plan")}</span>
              </Link>

            </li>
            <li>
              <Link
                className={`${sellerApproval && this.state.isSubscribe ? "" : "dis-able"}`}
                to={`${sellerApproval ? "/slot-manage" : ""}`}>
                {/* <i className="ri-pencil-ruler-2-line"></i> */}
                <i className="ri-time-line"></i>
                <span className="ml-1">{this.props.t("Create Slot")}</span>
              </Link>
            </li>
            <li>
              <Link to={`${sellerApproval ? "/off-days-manage" : ""}`}
                className={`${sellerApproval && this.state.isSubscribe ? "" : "dis-able"}`}
              >
                {/* <i className="ri-pencil-ruler-2-line"></i> */}
                <i className="ri-time-line"></i>
                <span className="ml-1">{this.props.t("Off Week Days")}</span>
              </Link>
            </li>
            <li>
              <Link to={`${sellerApproval ? "/order-history" : ""}`}
                className={`${sellerApproval && this.state.isSubscribe ? "" : "dis-able"}`}
              >
                {/* <i className="ri-pencil-ruler-2-line"></i> */}
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Order History")}</span>
              </Link>
            </li>

            <li>
              <Link to={`${sellerApproval ? "/#" : ""}`}
                className={`has-arrow waves-effect ${sellerApproval && this.state.isSubscribe ? "" : "dis-able"}`}
              >
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Seller Booking")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to={`${sellerApproval ? "/pending-booking" : ""}`}
                    className={`${sellerApproval && this.state.isSubscribe ? "" : "dis-able"}`}
                  >
                    {this.props.t("Manage Pending Booking")}
                  </Link>
                </li>

                <li>
                  <Link to={`${sellerApproval ? "/accept-booking" : ""}`}
                    className={`${sellerApproval && this.state.isSubscribe ? "" : "dis-able"}`}
                  >
                    {this.props.t("Manage Accepted Booking")}
                  </Link>
                </li>

                <li>
                  <Link to={`${sellerApproval ? "/cancel-booking" : ""}`}
                    className={`${sellerApproval && this.state.isSubscribe ? "" : "dis-able"}`}
                  >
                    {this.props.t("Manage Cancelled Booking")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to={`${sellerApproval ? "/wallet" : ""}`}
                className={`${sellerApproval && this.state.isSubscribe ? "" : "dis-able"}`}
              >
                {/* <i className="ri-pencil-ruler-2-line"></i> */}
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">
                  {this.props.t("My Wallet")}
                </span>
              </Link>
            </li>
            <li>
              <Link to={`${sellerApproval ? "/withdraw-balance" : ""}`}
                className={`${sellerApproval && this.state.isSubscribe ? "" : "dis-able"}`}
              >
                {/* <i className="ri-pencil-ruler-2-line"></i> */}
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">
                  {this.props.t("Withdraw Balance")}
                </span>
              </Link>
            </li>

            <li>
              <Link to={`${sellerApproval ? "/user-review" : ""}`}
                className={`${sellerApproval && this.state.isSubscribe ? "" : "dis-able"}`}
              >
                {/* <i className="ri-pencil-ruler-2-line"></i> */}
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">
                  {this.props.t("Manage User Review & Rating")}
                </span>
              </Link>
            </li>

            <li>
              <Link to={`${sellerApproval ? "/#" : ""}`}
                className={`has-arrow waves-effect ${sellerApproval && this.state.isSubscribe ? "" : "dis-able"}`}
              >
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Chats")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/chat"
                    className={`${sellerApproval && this.state.isSubscribe ? "" : "dis-able"}`}
                  >
                    {this.props.t("Manage users chatlist")}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withNamespaces()(SidebarContent))
);

