import React, { useState } from "react";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { ToastContainer, toast } from "react-toastify";
import StripeCheckout from "react-stripe-checkout";


function SubscriptionPopUp({ item, setShow }) {
  const [payment, setpayment] = useState("");
  const handlepayment = (e) => {
    console.log(e.target.value);
    setpayment(e.target.value);
  };

    const userData = reactLocalStorage.getObject("userData");
    const onToken = (token, addresses) => {
        console.log("token", token);
        console.log("addresses", addresses);
       
          stripeapicall(token.id);
        
      };
      const stripeapicall = async (token) => {
        console.log(token);
        const card = {
          amount:  item.price * 100,
          currency: userData.currency,
          source: token,
          description: "MySpritual Service Payment",
        };
    
        let result = await fetch("https://api.stripe.com/v1/charges", {
          headers: {
            // Use the correct MIME type for your server
            Accept: "application/json",
            // Use the correct Content Type to send data to Stripe
            "Content-Type": "application/x-www-form-urlencoded",
            // Use the Stripe publishable key as Bearer
            Authorization: `Bearer sk_test_51JavJLSB5KAxkRypR3jypiPJk4I9nQ7L9uGMqDwQSPXCEhwFhYRMGVLpp5UvQTXFYJ6GQ4MqXfAW1cVFxbqEvpR900Bc3ynSKr`,
          },
          // Use a proper HTTP method
          method: "post",
          // Format the credit card data to a string of key-value pairs
          // divided by &
          body: Object.keys(card)
            .map((key) => key + "=" + card[key])
            .join("&"),
        }).then((response) => response.json());
    
        console.log("resultt tokrn", result);
    
        buySubscription(result.balance_transaction);
      };
      const buySubscription = async (Transaction) => {
        let sendData = {
          userid: userData._id,
          subscr_id: item._id,
          seller_comission: item.seller_comission,
          price: item.price,
          no_of_listing: item.no_of_listing,
          currency: userData.currency,
          transaction:Transaction
        };
        console.log("object", sendData);
    
        let result = await HttpClient.requestData(
          "subscription-purchase",
          "POST",
          sendData
        );
        console.log("result", result);
        if (result && result.status) {
          reactLocalStorage.setObject("userData", result.userData);
          const type = reactLocalStorage.getObject("userData").type;
          if (type === "Seller") {
            reactLocalStorage.setObject("sellerType", { status: true });
          } else if (type === "User") {
            reactLocalStorage.setObject("sellerType", { status: false });
          }
          toast.success(result.message, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        //   fetchSublist();
    
          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 2000);
        } else {
          toast.error(result.message, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      };
    
  const rating = true;
  return (
    <div className="Rating">
          <ToastContainer />
      <div className="modal-dialog">
        {/* <ToastContainer /> */}
        <div className="modal-content pt-5 px-5 pb-4">
          <div className="">
            <span></span>
            <button
              type="button"
              className="close Single_close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setShow(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="mb-3" style={{textAlign:"center"}}>
            <h3><b>Stripe Payment</b></h3> 
          </div>
          <div className="mb-4" style={{display:"grid"}}>
            
          <div className="custom-control custom-radio">
                {/* <input
                  id="stripe"
                  name="paymentMethod"
                  type="radio"
                  className="custom-control-input"
                  value="stripe"
                  checked={payment == "stripe" ? true : false}
                  onChange={handlepayment}
                /> */}
                {/* <label className="custom-control-label" htmlFor="stripe">
                  Stripe
                </label> */}
              </div>
          <StripeCheckout
                token={onToken}
                stripeKey="pk_test_51JavJLSB5KAxkRypwpyFZVGF7Mxwel9iBFVqCbIaqDQERfj9A6NoeJEaBBZnnGRgSCasukWcLtWICm7SI0CtSDAl00bKUxkGVB"
                amount={( item.price) * 100}
                name="Astrophy Service"
                description="Pay & Buy"
                currency={userData.currency}
                shippingAddress={true}
              />
            <h6 className=" mb-2" style={{ fontSize: "18px" }}></h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionPopUp;
