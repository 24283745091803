import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import StartApp from "../pages/ChooseDesign/startApp";
import ChooseDesign from "../pages/ChooseDesign/index";
import CreateApp from "../pages/CreateApp/index";
import WelcomeScreen from "../pages/ChooseDesign/welcomeScreen";
import Quizlist from "../pages/QuizList/index";
import MoreQuestion from "../pages/AddMoreQuestions/index";
import Hobby from "../pages/Hobby/index";
import Updatehobby from "../pages/Updatehobby/index";

import KnowYourCustomer from "../pages/QuizList/knowCustomer";

import Monitize from "../pages/Monitize/index";
import Communicate from "../pages/Communicate/index";
import Forums from "../pages/Forums/index";
import Showroom from "../pages/Showroom/index";
import Directory from "../pages/Directory/index";
import Chat from "../pages/chats/chat";

// Subscrption
import Subscrption from "../pages/Subscription/index";

// Application
import App from "../pages/App/index";

import Category from "./../pages/Category/index";
import SubCategory from "./../pages/Category/subCategory";
// Product

//jd3 category
import Jdcategory from "./../pages/Jdcategory/index";
import Genre from "./../pages/Jdcategory/genre";

import Product from "./../pages/Product/index";
import ProductAdd from "./../pages/Product/create";

//Add banner

import Addbanner from "./../pages/Addbanner";

//Add banner
import AddSubscription from "../pages/subscriptionPlan/Index"
// import AddSubscription from "./../pages/subscriptionplan";

// Events

import Events from "./../pages/Event";

// Advertisement

import Advertisement from "./../pages/Advertise";

// Message

import UserChat from "./../pages/Message/user";
import ApponwerChat from "./../pages/Message/appowner";

// Profile

import Profile from "./../pages/Profile/index";

// Timeline

import Timeline from "./../pages/Timeline";

import sharePanel from "./../pages/Timeline/share";
import requestPanel from "./../pages/Timeline/request";
import Faq from "./../pages/Support&Faq/faq";

import Banner from "./../pages/Baner/index";

import Test from "../pages/Test/index";

import Video from "./../pages/Video/index";
import Series from "./../pages/Series/index";
import Episodes from "./../pages/Series/episodes";

import Tv from "./../pages/Tv/index";
import Training from "./../pages/jd3Event/index";

import AllVideos from "./../pages/Series/allTypes";

import ManageTypes from "./../pages/Jdcategory/type";

import Users from "./../pages/User";

import Price from "../pages/Price/index";

import UserSubscription from "./../pages/User/subscription";
import Subscribtionhistory from "./../pages/Subscriptionhistory";
import OneSubHistory from "./../pages/OneSubHistory";
import Userlist from "./../pages/Userlist";
import Sellerlist from "./../pages/Sellerlist";
import ServiceCategory from "./../pages/ServiceCategory";
import SubserviceCategory from "./../pages/SubserviceCategory";
import ShopServicecategory from "./../pages/ShopServiceCategory";
import SlotManage from "./../pages/SlotManage";
import OffDays from "./../pages/OffDays/Index";
import AboutUs from "./../pages/AboutUs/AboutUs";
import ContactUs from "./../pages/ContactUs/ContactUs";
import OderHistory from "./../pages/OderHistory";
import SellerPending from "./../pages/SellerAcceptReject";
import Acceptbooking from "./../pages/SellerAcceptReject/acceptbooking";
import Cancelbooking from "./../pages/SellerAcceptReject/cancelbooking";
import UserReview from "./../pages/userreview";

// Forms
import FormElements from "../pages/Forms/FormElements";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";

import UiCards from "../pages/Ui/UiCards";

import UiGeneral from "../pages/Ui/UiGeneral";
import TermsCondition from "../pages/TermsCondition/TermsCondition";
import Privacy from "../pages/PrivacyPolicy/Privacy";
import CreateShop from "../pages/CreateShop/CreateShop";
import Review from "../pages/Review/Review";
import Mywallet from "../pages/Mywallet";
import WithdrawBalance from "../pages/Mywallet/withdrawbalance";


const authProtectedRoutes = [
  { path: "/ui-cards", component: UiCards },

  { path: "/ui-general", component: UiGeneral },

  // Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },

  { path: "/dashboard", component: Dashboard },
  { path: "/proposed-showroom", component: Showroom },
  { path: "/published-showroom", component: Showroom },
  { path: "/startApp", component: StartApp },
  { path: "/choose-design", component: ChooseDesign },
  { path: "/create-app", component: CreateApp },
  { path: "/welcome-screen", component: WelcomeScreen },
  { path: "/communicate", component: Communicate },
  { path: "/monitize", component: Monitize },
  { path: "/know-your-customer", component: KnowYourCustomer },
  { path: "/quizlist", component: Quizlist },
  { path: "/hobbies", component: Hobby },
  { path: "/updatehobby:id", component: Updatehobby },
  { path: "/forum", component: Forums },

  { path: "/directory", component: Directory },
  // subs
  { path: "/subscrption", component: Subscrption },
  // app
  { path: "/application", component: App },

  // product
  { path: "/category", component: Category },
  { path: "/sub-category", component: SubCategory },
  { path: "/jd3category", component: Jdcategory },
  { path: "/genre", component: Genre },
  { path: "/product", component: Product },
  { path: "/product-add", component: ProductAdd },
  { path: "/add-banner", component: Addbanner },
  { path: "/add-banner", component: Addbanner },

  //add subscription plan

  { path: "/subscription-plan", component: AddSubscription },
  { path: "/subscription-history", component: Subscribtionhistory },
  { path: "/onesubscription-history", component: OneSubHistory },
  { path: "/userlist", component: Userlist },
  { path: "/sellerlist", component: Sellerlist },
  { path: "/service-category", component: ServiceCategory },
  { path: "/subservice-category", component: SubserviceCategory },
  { path: "/shopservice-category", component: ShopServicecategory },
  { path: "/slot-manage", component: SlotManage },
  {path: "/off-days-manage",component:OffDays},
  { path: "/about-us", component: AboutUs },
  { path: "/contact-us", component: ContactUs },
  { path: "/terms-condition", component: TermsCondition },
  { path: "/privacy-policy", component: Privacy },
  { path: "/create-shop", component: CreateShop },
  { path: "/review", component: Review },
  { path: "/order-history", component: OderHistory },
  { path: "/pending-booking", component: SellerPending },
  { path: "/accept-booking", component: Acceptbooking },
  { path: "/cancel-booking", component: Cancelbooking },
  { path: "/user-review", component: UserReview },
  { path: "/chat", component: Chat },
  { path: "/wallet", component: Mywallet },
  { path: "/withdraw-balance", component: WithdrawBalance },

  // Events
  { path: "/events", component: Events },

  { path: "/test", component: Test },

  // MoreQuestion
  { path: "/morequestion:id", component: MoreQuestion },

  // Advertise
  { path: "/advertisement", component: Advertisement },

  // share panel

  { path: "/share-panel", component: sharePanel },
  { path: "/request-panel", component: requestPanel },
  // Timeline
  { path: "/timeline", component: Timeline },

  // messahe
  { path: "/user-chat", component: UserChat },
  { path: "/appowner-chat", component: ApponwerChat },

  // Profile
  { path: "/faq", component: Faq },
  { path: "/profile", component: Profile },

  // Banner

  { path: "/banner", component: Banner },

  { path: "/manageprice", component: Price },

  // Videos

  { path: "/movies", component: Video },

  { path: "/series", component: Series },
  { path: "/episodes/:id", component: Episodes },

  { path: "/tv-shows", component: Tv },
  { path: "/training", component: Training },
  { path: "/videos", component: AllVideos },

  { path: "/types", component: ManageTypes },

  // Users

  { path: "/users", component: Users },
  { path: "/user-subscription", component: UserSubscription },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login/:token", component: Login },
  // { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/auth-lock-screen", component: AuthLockScreen },
];

export { authProtectedRoutes, publicRoutes };
