import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Peer from "peerjs";
import * as uuid from "uuid";
import Firebase from "./firebase";
import moment from "moment";
import { reactLocalStorage } from "reactjs-localstorage";
import myMusic from "../../assets/classical-demo.mp3";

const configuration = {
  iceServers: [
    { url: "stun:stun.l.google.com:19302" },
    { url: "stun:stun1.l.google.com:19302" },
    { url: "stun:stun2.l.google.com:19302" },
    { url: "stun:stun3.l.google.com:19302" },
    { url: "stun:stun4.l.google.com:19302" },
    {
      url: "turn:numb.viagenie.ca",
      credential: "Bokachoda@2020",
      username: "soumya.webhibe@gmail.com",
    },
  ],
};

var peerServer = null;
var myStream = null;
var remoteUserId = "";
var remoteUserStream = null;
var peerCall = null;
var callNodeId = null;
var callIsRecieved = false;
var hour = 0;
var min = 0;
var sec = 0;
var timerRef = null;

const VideoModal = (props) => {
  const { remoteData, roomId, type, chatRoomId } = props;

  const [iconoff, setIconoff] = useState("");
  const [recieved, setRecieved] = useState(false);
  const [remoteUserToken, setRemoteUserToken] = useState("");
  const [callState, setCallState] = useState("Connecting");
  const [audioMute, setAudioMute] = useState(false);
  const [remoteUserMute, setRemoteUserMute] = useState(false);
  const [refress, setRefress] = useState(false);

  const [audio] = useState(new Audio(myMusic));
  const myUserData = reactLocalStorage.getObject("userData");

  useEffect(() => {
    getUserMedia();
    return () => {
      // stopvideo();
    };
  }, []);

  useEffect(() => {
    if (callState == "Recieved") {
      // console.log("call start")

      startTimer();
    }
  }, [callState]);

  const startTimer = () => {
    let re = false;
    timerRef = setInterval(() => {
      if (sec < 59) {
        sec = sec + 1;
      } else {
        min = min + 1;
        sec = 0;
      }

      if (min == 60) {
        min = 0;
        hour = hour + 1;
      }
      // console.log("balance", balance)
      setRefress(!re);
      re = !re;
    }, 1000);
  };

  const FBGetValue = (nodeId) => {
    Firebase.database()
      .ref(`/call/${myUserData._id}/${nodeId}`)
      .on("value", (snapshot) => {
        let data = snapshot.val();
        setCallState(data.status);

        if (data.status == "Recieved") {
          // InCallManager.stopRingtone();
          // InCallManager.stopRingback();
        }

        if (data.muted) {
          setRemoteUserMute(true);
        } else {
          setRemoteUserMute(false);
        }

        if (data.endStatus) {
          endCall(data.status);
        }
      });
  };

  const getUserMedia = () => {
    navigator.getUserMedia(
      {
        audio: true,
        video: {
          height: 300,
          width: 300,
          frameRate: 30,
        },
      },
      (stream) => {
        // console.log("stream", stream)
        const ownVideoGreed = document.getElementById("ownVideo");
        // console.log("videoGreed", videoGreed)
        ownVideoGreed.srcObject = stream;
        // ownVideoGreed.muted = true
        ownVideoGreed.addEventListener("loadedmetadata", () => {
          ownVideoGreed.play();
        });
        initCall(stream);
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const initCall = (stream) => {
    myStream = stream;
    peerServer = new Peer(undefined, {
      host: "peer.astrophy.com",
      path: "/",
      secure: true,
      config: configuration,
    });

    peerServer.on("open", (userId) => {
      if (type == "Incomming") {
        audio.play();
        callNodeId = remoteData.nodeId;
        FBGetValue(remoteData.nodeId);
        updateMyFb({ status: "Ringing" });
        updateClientFb({ status: "Ringing" });
      } else {
        // console.log("remoteData", remoteData)
        let callId = uuid.v4();
        callNodeId = callId;

        let myData = {
          userId: remoteData.userId,
          name: `${remoteData.name}`,
          nodeId: callId,
          time: moment().format(),
          type: "Outgoing",
          status: "Connecting",
          endStatus: false,
          videoCall: true,
        };

        Firebase.database()
          .ref(`/call/${myUserData._id}/${callId}`)
          .set(myData)
          .then(() => {
            FBGetValue(callId);
          });

        let clientData = {
          _id: myUserData._id,
          firstName: myUserData.firstName,
          lastName: myUserData.lastName,
          peerId: userId,
          time: moment().format(),
          type: "Incomming",
          status: "Connecting",
          nodeId: callId,
          endStatus: false,
          videoCall: true,
        };

        Firebase.database()
          .ref(`/call/${remoteData.userId}/${callId}`)
          .set(clientData);
      }
    });

    peerServer.on("call", (call) => {
      const conn = peerServer.connect(call.peer);
      call.answer(stream);

      call.on("stream", (userStream) => {
        console.log("userStream peer", userStream);
        peerCall = call;
        remoteUserStream = userStream;
        const videoGreed = document.getElementById("remote-video");
        const remoteImage = document.getElementById("remote-image");
        // console.log("videoGreed",videoGreed)
        videoGreed.srcObject = userStream;
        videoGreed.addEventListener("loadedmetadata", () => {
          videoGreed.play();
          remoteImage.style.display = "contents";
          videoGreed.style.display = "block";
        });
      });

      call.on("close", () => {
        console.log("streamstream1");
      });
    });
  };

  const connectUser = (userId) => {
    // console.log("called")
    let data = {};
    const conn = peerServer.connect(userId);
    const call = peerServer.call(userId, myStream);

    call.on("stream", (remoteVideoStream) => {
      if (remoteVideoStream) {
        peerCall = call;
        console.log("user stream", remoteVideoStream);
        remoteUserStream = remoteVideoStream;
        const videoGreed = document.getElementById("remote-video");
        const remoteImage = document.getElementById("remote-image");
        console.log("videoGreed", videoGreed);
        videoGreed.srcObject = remoteVideoStream;
        videoGreed.addEventListener("loadedmetadata", () => {
          videoGreed.play();
          remoteImage.style.display = "contents";
          videoGreed.style.display = "block";
        });
      }
    });
    call.on("close", () => {
      console.log("streamstream");
      // dispatch({ type: REMOVE_REMOTE_STREAM, payload: peers[userId].stream.id })
      // this.props.dispatch(removeRemoteStream(peers[userId].stream.id))
    });
  };

  const updateMyFb = (data) => {
    Firebase.database()
      .ref(`/call/${myUserData._id}/${callNodeId}`)
      .update(data)
      .then(() => {
        console.log("done");
      });
  };

  const updateClientFb = (data) => {
    Firebase.database()
      .ref(`/call/${remoteData.userId}/${callNodeId}`)
      .update(data)
      .then(() => {});
  };

  const reciveCall = () => {
    connectUser(remoteData.peerId);
    audio.pause();
    updateMyFb({ status: "Recieved" });
    updateClientFb({ status: "Recieved" });
    setRecieved(true);
  };

  const endCall = (callStat) => {
    remoteUserStream = null;
    if (peerCall != null) {
      peerCall.close();
    }
    clearInterval(timerRef);

    if (type != "Incomming") {
      let callMsgRef = Firebase.database()
        .ref(`/chat/${chatRoomId}/messages`)
        .push();

      let msdId = callMsgRef.key;
      console.log("callState", callStat);

      callMsgRef.set({
        sender_id: myUserData._id,
        type: "call",
        msgid: msdId,
        send_time: moment().format(),
        roomid: chatRoomId,
        name: myUserData.firstName + " " + myUserData.lastName,
        callDuration: `${hour.toString().padStart(2, "0")} : ${min
          .toString()
          .padStart(2, "0")} : ${sec.toString().padStart(2, "0")}`,
        callTime: moment().format(),
        callStatus: callStat == "Recieved" ? "Recieved" : "Outgoing",
      });
    }

    hour = 0;
    min = 0;
    sec = 0;

    myStream.getTracks().forEach(function (track) {
      track.stop();
    });
    audio.pause();
    props.callBackVideo(false);
  };

  return (
    <div>
      <div className="modal-dialog video-modal my-5 ">
        <div className="modal-content call_modal w-100">
          <div className="call-body   ">
            <div className="">
              <div className="_large_video">
                <img
                  src="https://png.pngitem.com/pimgs/s/130-1300344_user-symbol-png-transparent-png.png"
                  height="100%"
                  width="100%"
                  id="remote-image"
                  style={{
                    objectFit: "contain",
                    display: "block",
                  }}
                />
                <video
                  // src="https://www.w3schools.com/html/mov_bbb.mp4"
                  width="100%"
                  height="100%"
                  id="remote-video"
                  style={{
                    display: "contents",
                  }}
                  muted={remoteUserMute}
                  //   autoPlay
                />
                <div className="_video_intro d-flex pl-lg-5">
                  <button
                    className="_meet_act_btn px-3"
                    style={{ width: "auto" }}
                  >
                    {remoteData.name}
                  </button>
                  <button
                    className="_meet_act_btn px-3"
                    style={{ width: "auto" }}
                  >
                    {callState != "Recieved"
                      ? `${callState}...`
                      : `${hour.toString().padStart(2, "0")} : ${min
                          .toString()
                          .padStart(2, "0")} : ${sec
                          .toString()
                          .padStart(2, "0")}`}
                  </button>
                </div>
                <div>
                  <img
                    src="https://png.pngitem.com/pimgs/s/130-1300344_user-symbol-png-transparent-png.png"
                    alt="img"
                    className="video-img"
                    style={{
                      display: "contents",
                    }}
                  />

                  <video
                    src="https://www.w3schools.com/html/mov_bbb.mp4"
                    width="100%"
                    height="100%"
                    id="ownVideo"
                    className="own-video"
                    muted={true}
                    style={{
                      display: "block",
                    }}
                    //   autoPlay
                  />
                </div>
              </div>
            </div>
          </div>
          <div className=" down-part mt-3">
            <div className="d-flex justify-content-around">
              {/* {iconoff === "speaker" ? (
                <button
                  type="button"
                  className="btn down-btn"
                  onClick={() => setIconoff("")}
                >
                  <i className="fas fa-volume-mute"></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn down-btn"
                  onClick={() => setIconoff("speaker")}
                >
                  <i className="fas fa-volume-down"></i>
                </button>
              )}
              {iconoff === "micro" ? (
                <button
                  type="button"
                  className="btn down-btn"
                  onClick={() => setIconoff("")}
                >
                  <i className="fas fa-microphone-slash"></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn down-btn"
                  onClick={() => setIconoff("micro")}
                >
                  <i className="fas fa-microphone"></i>
                </button>
              )}
              {iconoff === "pause" ? (
                <button
                  type="button"
                  className="btn down-btn"
                  onClick={() => setIconoff("")}
                >
                  <i className="fas fa-video-slash"></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn down-btn"
                  onClick={() => setIconoff("pause")}
                >
                  <i className="fas fa-video"></i>
                </button>
              )} */}
              {!recieved && type == "Incomming" ? (
                <button
                  type="button"
                  className="btn receive-btn"
                  onClick={reciveCall}
                >
                  <i className="fas fa-phone" />
                </button>
              ) : null}

              {callState == "Recieved" ? (
                !audioMute ? (
                  <button
                    type="button"
                    className="btn down-btn"
                    onClick={() => {
                      setAudioMute(true);
                      updateClientFb({ muted: true });
                    }}
                  >
                    <i className="fas fa-microphone-slash"></i>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn down-btn"
                    onClick={() => {
                      setAudioMute(false);
                      updateClientFb({ muted: false });
                    }}
                    style={{
                      borderRadius: 30,
                      background: "#ffffff33",
                    }}
                  >
                    <i
                      className="fas fa-microphone-slash"
                      aria-hidden="true"
                    ></i>
                  </button>
                )
              ) : null}

              <button
                type="button"
                className="btn decline-btn "
                onClick={() => {
                  updateMyFb({ endStatus: true });
                  updateClientFb({ endStatus: true });
                }}
              >
                <i className="fas fa-phone"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
