import React, { Component } from "react";
import { CSVLink } from "react-csv";
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    FormGroup,
    Label,
    Input,
    CustomInput,
    Button,
    UncontrolledAlert,
    InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";

//import ModalPendingBooking from "./ModalPendingBooking";
const header = [

    { label: "Sl.", key: "sl" },
    { label: "Order Id", key: "orderid" },
    { label: "Amount", key: "amount" },
]
class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Claimable Balance List", link: "#" },
                { title: "Manage Claimable Balance List", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            // page states
            name: "",
            email: "",
            mobile: "",
            password: "",
            data: [],
            alert: false,
            message: "",
            type: "",
            result: [],
            edit_enable: false,
            edit_item_id: "",
            image_select: false,
            img_url: "",
            userId: "",
            userDetails: {},

            image_select: false,
            img_url: "",
            isOpenLike: false,

            bookingid: "",
            modaluserid: "",
            cancelBooking: false,
            pincode: "",
        };
        // this.handleDefault = this.handleDefault.bind(this);
    }



    componentDidMount = async () => {
        this.checkUser();
    };



    checkUser = async () => {
        let user = await reactLocalStorage.getObject("userData");
        console.log("user", user);
        if (user != null && Object.keys(user).length > 0) {
            this.setState({
                userId: user._id,
            });
            this.fetchData(user._id);
        }

    };

    WithdrawBalance = async (id) => {
        console.log("id--",id);
        let result = await HttpClient.requestData("withdraw_one_commission/" + id, "PUT");
        // console.log("send Request", result);
        if (result && result.status) {

            this.setState({
                alert: true,
                message: "Commission withdrawn successfully",
                type: "success",

            });
            setTimeout(() => {
                this.setState({
                    alert: false,
                    message: "",
                    type: "",
                });
            }, 3000);

            this.fetchData(this.state.userId);

        } else {
            this.setState({
                alert: true,
                message: result.message,
                type: "danger",
            });



            setTimeout(() => {
                this.setState({
                    alert: false,
                    message: "",
                    type: "",
                });
            }, 3000);
        }
    };

    fetchData = async (id) => {
        console.log(id);
        let result = await HttpClient.requestData("claimable_commissions/" + id, "GET");
        console.log("result", result);
        if (result && result.status) {
            this.setState({
                result: result.service_data,
            })

            let data = [];
            let i = 1;

            this.state.result.length > 0 && this.state.result.forEach((element, index) => {


                let rows = {
                    sl: i,
                    transactionid: element.transactionid,
                    orderid: element.order_id,
                    amount: element.seller_commission,
                    paydate_on: moment(element.created_on).format('MMMM Do YYYY  '),

                    withdraw: element.seller_apply ? (
                        <Button
                            color="secondary"
                        >
                            {" "}
                            Withdrawn
                        </Button>
                    ) : (
                        <Button
                            color="success"
                            onClick={() => {
                                this.WithdrawBalance(element._id);
                            }}


                        >
                            {" "}
                            Withdraw
                        </Button>
                    ),
                };
                i++;
                data.push(rows);
            });
            this.setState({
                data: data,
            });
        } else {
            this.setState({
                data: [],
            });
        }
    };


    render() {
        const data = {
            columns: [
                {
                    label: "Sl.",
                    field: "sl",
                    width: 150,
                },
                {
                    label: "Order Id",
                    field: "orderid",
                    width: 270,
                },
                {
                    label: "Amount",
                    field: "amount",
                    width: 270,
                },
                // {
                //   label: "Paid On",
                //   field: "paydate_on",
                //   width: 270,
                // },
                {
                    label: "Action",
                    field: "withdraw",
                    width: 270,
                },
                // {
                //   label: "Transaction Image",
                //   field: "Image",
                //   width: 270,
                // },
            ],
            rows: this.state.data,


        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.state.breadcrumbItems[0].title}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col lg={12}>
                                {this.state.alert ? (
                                    <UncontrolledAlert
                                        color={this.state.type}
                                        className="alert-dismissible fade show"
                                        role="alert"
                                    >
                                        {this.state.type == "warning" ? (
                                            <i className="mdi mdi-alert-outline mr-2"></i>
                                        ) : this.state.type == "success" ? (
                                            <i className="mdi mdi-check-all mr-2"></i>
                                        ) : this.state.type == "danger" ? (
                                            <i className="mdi mdi-block-helper mr-2"></i>
                                        ) : null}
                                        {this.state.message}
                                    </UncontrolledAlert>
                                ) : null}
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12}>
                                {this.state.data.length > 0 ?
                                    <CSVLink
                                        data={this.state.data}
                                        headers={header}
                                        filename={"PendingBookingList.csv"}
                                        className="btn btn-success">
                                        Export Report in CSV
                                    </CSVLink> : null}
                                <div className="card p-3">
                                    <MDBDataTable responsive bordered data={data} />
                                </div>
                            </Col>
                        </Row>
                    </Container>{" "}

                </div>

            </React.Fragment>
        );
    }
}

export default FormElements;