import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Peer from "peerjs";
import * as uuid from "uuid";
import Firebase from "./firebase";
import moment from "moment";
import { reactLocalStorage } from "reactjs-localstorage";
import myMusic from "../../assets/classical-demo.mp3";

const configuration = {
  iceServers: [
    { url: "stun:stun.l.google.com:19302" },
    { url: "stun:stun1.l.google.com:19302" },
    { url: "stun:stun2.l.google.com:19302" },
    { url: "stun:stun3.l.google.com:19302" },
    { url: "stun:stun4.l.google.com:19302" },
    {
      url: "turn:numb.viagenie.ca",
      credential: "Bokachoda@2020",
      username: "soumya.webhibe@gmail.com",
    },
  ],
};

var peerServer = null;
var myStream = null;
var remoteUserId = "";
var remoteUserStream = null;
var peerCall = null;
var callNodeId = null;
var hour = 0;
var min = 0;
var sec = 0;
var timerRef = null;

const AudioModal = (props) => {
  const { remoteData, roomId, type, chatRoomId } = props;
  const [iconoff, setIconoff] = useState("");
  const [recieved, setRecieved] = useState(false);
  const [remoteUserToken, setRemoteUserToken] = useState("");
  const [callState, setCallState] = useState("Connecting");
  const [audioMute, setAudioMute] = useState(false);
  const [remoteUserMute, setRemoteUserMute] = useState(false);
  const [refress, setRefress] = useState(false);

  const [audio] = useState(new Audio(myMusic));
  const myUserData = reactLocalStorage.getObject("userData");
  useEffect(() => {
    // console.log("remote", props.remoteData);
    getUserMedia();
  }, []);

  useEffect(() => {
    if (callState == "Recieved") {
      // console.log("call start")

      startTimer();
    }
  }, [callState]);

  const startTimer = () => {
    let re = false;
    timerRef = setInterval(() => {
      if (sec < 59) {
        sec = sec + 1;
      } else {
        min = min + 1;
        sec = 0;
      }

      if (min == 60) {
        min = 0;
        hour = hour + 1;
      }
      // console.log("balance", balance)
      setRefress(!re);
      re = !re;
    }, 1000);
  };

  const getUserMedia = () => {
    navigator.getUserMedia(
      { audio: true },
      (stream) => {
        // console.log("stream", stream)
        initCall(stream);
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const FBGetValue = (nodeId) => {
    Firebase.database()
      .ref(`/call/${myUserData._id}/${nodeId}`)
      .on("value", (snapshot) => {
        let data = snapshot.val();
        setCallState(data.status);

        if (data.status == "Recieved") {
          // InCallManager.stopRingtone();
          // InCallManager.stopRingback();
        }

        if (data.muted) {
          setRemoteUserMute(true);
        } else {
          setRemoteUserMute(false);
        }

        if (data.endStatus) {
          endCall(data.status);
        }
      });
  };

  const initCall = (stream) => {
    myStream = stream;
    peerServer = new Peer(undefined, {
      host: "peer.astrophy.com",
      path: "/",
      secure: true,
      config: configuration,
    });

    peerServer.on("open", (userId) => {
      console.log("userid", userId);
      if (type == "Incomming") {
        audio.play();
        callNodeId = remoteData.nodeId;
        FBGetValue(remoteData.nodeId);
        updateMyFb({ status: "Ringing" });
        updateClientFb({ status: "Ringing" });
      } else {
        // console.log("remoteData._id", remoteData, myUserData)
        let callId = uuid.v4();
        callNodeId = callId;
        let myData = {
          userId: remoteData.userId,
          name: `${remoteData.name}`,
          nodeId: callId,
          time: moment().format(),
          type: "Outgoing",
          status: "Connecting",
          endStatus: false,
        };

        Firebase.database()
          .ref(`/call/${myUserData._id}/${callId}`)
          .set(myData)
          .then(() => {
            FBGetValue(callId);
          });

        let clientData = {
          _id: myUserData._id,
          firstName: myUserData.firstName,
          lastName: myUserData.lastName,
          peerId: userId,
          time: moment().format(),
          type: "Incomming",
          status: "Connecting",
          nodeId: callId,
          endStatus: false,
        };

        Firebase.database()
          .ref(`/call/${remoteData.userId}/${callId}`)
          .set(clientData);
      }
    });

    peerServer.on("call", (call) => {
      const conn = peerServer.connect(call.peer);
      call.answer(stream);

      call.on("stream", (userStream) => {
        console.log("userStream peer", userStream);
        peerCall = call;
        remoteUserStream = userStream;
        const videoGreed = document.getElementById("video-view-rtc");
        videoGreed.srcObject = userStream;
        videoGreed.addEventListener("loadedmetadata", () => {
          videoGreed.play();
        });
      });

      call.on("close", () => {
        console.log("streamstream1");
      });
    });
  };

  const connectUser = (userId) => {
    // console.log("called")
    let data = {};
    const conn = peerServer.connect(userId);
    const call = peerServer.call(userId, myStream);

    call.on("stream", (remoteVideoStream) => {
      if (remoteVideoStream) {
        peerCall = call;
        console.log("user stream", remoteVideoStream);
        remoteUserStream = remoteVideoStream;
        const videoGreed = document.getElementById("video-view-rtc");
        videoGreed.srcObject = remoteVideoStream;
        videoGreed.addEventListener("loadedmetadata", () => {
          videoGreed.play();
        });
      }
    });
    call.on("close", () => {
      console.log("streamstream");
    });
  };

  const updateMyFb = (data) => {
    Firebase.database()
      .ref(`/call/${myUserData._id}/${callNodeId}`)
      .update(data)
      .then(() => {
        console.log("done");
      });
  };

  const updateClientFb = (data) => {
    Firebase.database()
      .ref(`/call/${remoteData.userId}/${callNodeId}`)
      .update(data)
      .then(() => {});
  };

  const reciveCall = () => {
    connectUser(remoteData.peerId);
    audio.pause();
    updateMyFb({ status: "Recieved" });
    updateClientFb({ status: "Recieved" });
    setRecieved(true);
  };

  const endCall = (callStat) => {
    remoteUserStream = null;
    if (peerCall != null) {
      peerCall.close();
    }
    clearInterval(timerRef);

    if (type != "Incomming") {
      let callMsgRef = Firebase.database()
        .ref(`/chat/${chatRoomId}/messages`)
        .push();

      let msdId = callMsgRef.key;
      console.log("callState", callStat);

      callMsgRef.set({
        sender_id: myUserData._id,
        type: "call",
        msgid: msdId,
        send_time: moment().format(),
        roomid: chatRoomId,
        name: myUserData.firstName + " " + myUserData.lastName,
        callDuration: `${hour.toString().padStart(2, "0")} : ${min
          .toString()
          .padStart(2, "0")} : ${sec.toString().padStart(2, "0")}`,
        callTime: moment().format(),
        callStatus: callStat == "Recieved" ? "Recieved" : "Outgoing",
      });
    }

    hour = 0;
    min = 0;
    sec = 0;

    myStream.getTracks().forEach(function (track) {
      track.stop();
    });
    audio.pause();
    props.callBackcall1(false);
  };

  return (
    <div>
      <div className="modal-dialog  my-3 ">
        <div className="modal-content call_modal" style={{ marginTop: "5rem" }}>
          <div className="call-body my-3 px-5 pt-4">
            <div className="text-center">
              <h5>{remoteData.name}</h5>
              {callState != "Recieved" ? (
                <p>{callState}...</p>
              ) : (
                <p>{`${hour.toString().padStart(2, "0")} : ${min
                  .toString()
                  .padStart(2, "0")} : ${sec.toString().padStart(2, "0")}`}</p>
              )}

              <img
                src="https://png.pngitem.com/pimgs/s/130-1300344_user-symbol-png-transparent-png.png"
                alt="img"
                className="audio-img"
                style={{
                  objectFit: "contain",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          </div>

          <video
            id="video-view-rtc"
            style={{
              display: "none",
            }}
            muted={remoteUserMute}
          />

          <div className=" down-part mt-5">
            <div className="d-flex justify-content-around">
              {!recieved && type == "Incomming" ? (
                <button
                  type="button"
                  className="btn receive-btn"
                  onClick={reciveCall}
                >
                  <i className="fas fa-phone" />
                </button>
              ) : null}

              {callState == "Recieved" ? (
                !audioMute ? (
                  <button
                    type="button"
                    className="btn down-btn"
                    onClick={() => {
                      setAudioMute(true);
                      updateClientFb({ muted: true });
                    }}
                  >
                    <i className="fas fa-microphone-slash"></i>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn down-btn"
                    onClick={() => {
                      setAudioMute(false);
                      updateClientFb({ muted: false });
                    }}
                    style={{
                      borderRadius: 30,
                      background: "#ffffff33",
                    }}
                  >
                    <i
                      className="fas fa-microphone-slash"
                      aria-hidden="true"
                    ></i>
                  </button>
                )
              ) : null}

              <button
                type="button"
                className="btn decline-btn"
                onClick={() => {
                  updateMyFb({ endStatus: true });
                  updateClientFb({ endStatus: true });
                }}
              >
                <i className="fas fa-phone"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioModal;
