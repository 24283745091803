import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import HttpClient from "./../../utils/HttpClient";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { reactLocalStorage } from "reactjs-localstorage";
//Import Components
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import EarningReports from "./EarningReports";
import Sources from "./Sources";
import RecentlyActivity from "./RecentlyActivity";
import RevenueByLocations from "./RevenueByLocations";
import ChatBox from "./ChatBox";
import LatestTransactions from "./LatestTransactions";
import Firebase from "../chats/firebase";
import AudioModal from "../chats/AudioModal";
import VideoModal from "../chats/VideoModal";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "My Spiritual", link: "#" },
        { title: "Dashboard", link: "#" },
      ],
      reports: [
        {
          icon: "ri-stack-line",
          title: "Number of Sales",
          value: "1452",
          rate: "2.4%",
          desc: "From previous period",
        },
        {
          icon: "ri-store-2-line",
          title: "Sales Revenue",
          value: "$ 38452",
          rate: "2.4%",
          desc: "From previous period",
        },
        {
          icon: "ri-briefcase-4-line",
          title: "Average Price",
          value: "$ 15.4",
          rate: "2.4%",
          desc: "From previous period",
        },
      ],

      result: [],
      total: 0,
      pending: 0,
      accept: 0,
      reject: 0,
      videoCall: false,
      call: false,
      callData: {},
    };
  }

  componentDidMount = async () => {
    this.checkUser();
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    console.log("user", user);
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user._id,
      });
      this.dasboardData(user._id);

      Firebase.database()
        .ref(`/call/${user._id}`)
        .on("child_added", (snapshot) => {
          if (snapshot.exists()) {
            let fbData = snapshot.val();
            console.log("fbData", fbData);
            if (fbData.type == "Incomming" && !fbData.endStatus) {
              console.log("fbData1", fbData.videoCall);
              this.setState({
                callData: fbData,
              });

              if (fbData.videoCall == true) {
                // console.log("bal")
                this.setState({ videoCall: true });
              } else {
                console.log("chal");
                this.setState({ call: true });
              }
            }
          }
        });
    }
  };

  dasboardData = async (id) => {
    let result = await HttpClient.requestData(
      "seller_account/booking-stat/" + id,
      "GET"
    );

    console.log("Booking Accept", result);
    if (result && result.status) {
      this.setState({
        result: result,
        total: result.total_bookings,
        pending: result.pending_bookings,
        accept: result.accepted_bookings,
        reject: result.rejected_bookings,
      });
      console.log(this.state.accept);
    } else {
    }
  };

  callBackcall = () => {
    this.setState({ call: false });
  };

  callBackcall1 = () => {
    this.setState({ videoCall: false });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Dashboard"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              {/* <Col xl={8}>
                                <Row>
                                    <MiniWidgets reports={this.state.reports} />
                                </Row>
                                
                                <RevenueAnalytics/>
                            </Col> */}
              {/* 
                            <Col xl={4}>

                                <SalesAnalytics name={"Sales"}/>

                                <EarningReports/>

                            </Col> */}
            </Row>

            <Row>
              {/* sources */}
              <Sources total={this.state.total ? this.state.total : 0} />

              {/* recent activity */}
              <RecentlyActivity
                pending={this.state.pending ? this.state.pending : 0}
              />

              {/* revenue by locations */}
              <RevenueByLocations
                accept={this.state.accept ? this.state.accept : 0}
              />
            </Row>

            <Row>
              <SalesAnalytics
                reject={this.state.reject ? this.state.reject : 0}
              />

              {/*                             
                            <LatestTransactions /> */}
            </Row>
          </Container>

          {this.state.call ? (
            <div
              className="modal fade single_modal show"
              id="exampleModal1"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <AudioModal
                status={true}
                remoteData={this.state.callData}
                type="Incomming"
                callBackcall1={this.callBackcall}
                image=""
              />
            </div>
          ) : null}

          {this.state.videoCall ? (
            <div
              className="modal fade single_modal show"
              id="exampleModal1"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <VideoModal
                status={true}
                remoteData={this.state.callData}
                type="Incomming"
                callBackVideo={this.callBackcall1}
                image=""
              />
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
