import React, { Component } from "react";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link } from "react-router-dom";
import img from "../../../src/assets/images/pro.jpeg";
//import ReactJsAlert from "reactjs-alert";
import "./chatlist.css";
import AudioModal from "./AudioModal";
import Firebase from "./firebase";
import { Row, Card, CardBody, Col, Input, Button, Spinner } from "reactstrap";
import SimpleBar from "simplebar-react";
// import BellIcon from "react-bell-icon";

import moment from "moment";
import VideoModal from "./VideoModal";

export default class verifymobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      status: false,
      title: "",
      msglist: [],
      message: "",
      modalStatus: this.props.status ? this.props.status : false,
      selectedFiles: [],
      filetype: "",
      firstFile: "",
      videoloading: false,
      adminCount: 0,
      userCount: 0,
      callmodal: false,
      videoModal: false,
      
    };
    this.messagesEndRef = React.createRef();
  }

  callBackcall = () => {
    this.setState({
      callmodal: false,
    });
  };
  callBackVideo = () => {
    this.setState({
      videoModal: false,
    });
  };
  componentDidMount() {
    console.log(this.props.roomid);
    console.log("uuiui", this.props.userid);
    console.log("sellerid", this.props.selleruserid);

    this.FetchMessageList();
    this.scrollToBottom();
  }

  FetchMessageList = async () => {
    console.log("hii");
    Firebase.database()
      .ref()
      .child("chat")
      .child(this.props.roomid)
      .child("messages")
      .on("value", (snapshot) => {
        let arr = [];
        if (snapshot.exists()) {
          console.log("IndividualResponseClick", snapshot.val());

          // snapshot.forEach((ele) => {
          //   let dom = {
          //     name: ele.val().name,
          //     msg: ele.val().message,
          //     send_time: moment(ele.val().send_time).fromNow(),
          //     roomid: ele.val().roomid,
          //     sender_id: ele.val().sender_id,
          //     msgimg: ele.val().msgimg ? ele.val().msgimg : "",
          //   };
          //   console.log("dom", dom);
          //   arr.push(dom);
          // });
          this.setState({
            msglist: Object.values(snapshot.val()),
          });
        }
      });
  };

  onCloseMethod = () => {
    this.setState({
      modalStatus: false,
    });

    this.props.onCloseCallBack(false, "close", "");
  };

  SendMsg = async (url) => {
    // if (this.state.message != "") {
    //creating msg id using push
    //using .key we can get the pushed id in return
    let date = Date.now();
    let date1 = moment(date).format("YYYY-MM-DD");
    let date2 = moment(date).format("HH:mm:ssZ");
    let msgTime = date1 + "T" + date2;

    let msgid = Firebase.database()
      .ref()
      .child("chat")
      .child(this.props.roomid)
      .child("messages")
      .push().key;
    console.log(msgid);
    //now we setting the msg body into the perticular msg id
    Firebase.database()
      .ref()
      .child("chat")
      .child(this.props.roomid)
      .child("messages")
      .child(msgid)
      .set(
        {
          sender_id: this.props.selleruserid,
          message: this.state.message,
          msgid: msgid,
          send_time: msgTime,
          roomid: this.props.roomid,
          name: this.props.name,
          msgimg:
            this.state.filetype == "image/jpeg" ||
              this.state.filetype == "image/png"
              ? url
              : "",

          //msgvideo: this.state.filetype == "video/mp4" ? url : "",
        },
        (error) => {
          if (error) {
            alert("something went wrong");
          } else {
            this.scrollToBottom();
            //alert("message has been sent successfully");
            this.setState({
              message: "",
              firstFile: "",
              selectedFiles: [],
            });
          }
        }
      );

    //////updating

    if (this.state.message != "") {
      Firebase.database()
        .ref()
        .child("chatlist")
        .child(this.props.selleruserid + "S")
        .child(this.props.userid)
        .update(
          {
            // displayName: this.props.name,
            lastMsg: this.state.message,
            lastMsgTime: msgTime,
            forimage: "",
            lastMsgBy: "Seller",
            // name: this.props.name,
            // roomid: this.props.roomid,
            // userId: this.props.userid,
          },
          (error) => {
            if (error) {
              alert("something went wrong");
            } else {

              // console.log("mesage", message);
              //alert("updated");
            }
          }
        );
    } else {
      Firebase.database()
        .ref()
        .child("chatlist")
        .child(this.props.selleruserid + "S")
        .child(this.props.userid)
        .update(
          {
            // displayName: this.props.name,
            lastMsg: this.state.message,
            lastMsgTime: msgTime,
            forimage: url,
            lastMsgBy: "Seller",
            // name: this.props.name,
            // roomid: this.props.roomid,
            // userId: this.props.userid,
          },
          (error) => {
            if (error) {
              alert("something went wrong");
            } else {

              // console.log("mesage", message);
              //alert("updated");
            }
          }
        );
    }

    if (this.state.message != "") {
      Firebase.database()
        .ref()
        .child("chatlist")
        .child(this.props.userid + "U")
        .child(this.props.selleruserid)
        .update(
          {
            //displayName: props.name,
            lastMsg: this.state.message,
            lastMsgTime: msgTime,
            forimage: "",
            lastMsgBy: "Seller",
            // name: props.name,
            // roomid: props.roomid,
            // userId: props.userid,
          },
          (error) => {
            if (error) {
              alert("something went wrong");
            } else {

              console.log("mesage", this.state.message);
              //alert("updated");
            }
          }
        );
    } else {
      Firebase.database()
        .ref()
        .child("chatlist")
        .child(this.props.userid + "U")
        .child(this.props.selleruserid)
        .update(
          {
            //displayName: props.name,
            lastMsg: this.state.message,
            lastMsgTime: msgTime,
            forimage: url,
            lastMsgBy: "Seller",
            // name: props.name,
            // roomid: props.roomid,
            // userId: props.userid,
          },
          (error) => {
            if (error) {
              alert("something went wrong");
            } else {

              console.log("mesage", this.state.message);
              //alert("updated");
            }
          }
        );
    }

    //}
  };

  scrollToBottom = () => {
    const target = this.messagesEndRef.current;
    target.scrollIntoView({
      top: target.scrollHeight,
      behavior: "smooth",
    });
  };

  uploadFile = async () => {
    let data = new FormData();
    data.append("image", this.state.firstFile);
    let result = await HttpClient.fireBaseImage(
      "image-uploadurl",
      "POST",
      data
    );
    console.log(result);
    if (result && result.status) {
      this.SendMsg(result.data);
    }
  };

  handlefilePick = async (event) => {
    let data = [];
    let file = {
      preview: URL.createObjectURL(event.target.files[0]),
    };
    this.setState({
      filetype: event.target.files[0].type,
      //firstFile: URL.createObjectURL(event.target.files[0]),
      firstFile: event.target.files[0],
      selectedFiles: [
        {
          preview: URL.createObjectURL(event.target.files[0]),
          type: event.target.files[0].type,
        },
      ],
    });
    console.log("selected files", this.state.selectedFiles);
    setTimeout(() => {
      console.log("selected files", this.state.firstFile);
    }, 3000);
  };

  render() {
    const { selectedOption } = this.state;
    const userData = reactLocalStorage.getObject("userData");
    // console.log("userData==", userData)
    return (
      <div
        className={this.state.modalStatus ? "modal show fade" : "modal fade"}
        id="add-new-task-modal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="add-new-task-modalLabel"
        aria-hidden="true"
        style={{ display: this.state.modalStatus ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered replyBox modal-lg">
          <div className="modal-content call_modal">
            <Card className="mb-0">
              {/* chat head area */}
              <CardBody className="border-bottom py-2">
                <div className="user-chat-border">
                  <Row>
                    <Col md={9} xs={9}>
                      <div className="d-flex">
                        <div className="my-auto mr-3">
                          {this.props.image != "" ? (
                            <img
                              src={this.props.image}
                              className="img-fluid rounded-circle"
                              style={{ height: "30px", width: "30px" }}
                            />
                          ) : (
                            <img
                              src={img}
                              className="img-fluid rounded-circle"
                              style={{ height: "30px", width: "30px" }}
                            />
                          )}
                        </div>
                        <h5 className="font-size-15 my-auto mr-auto">
                          {this.props.fullname}
                        </h5>
                      </div>
                    </Col>
                    <Col md={3} className="d-flex justify-content-end">
                      
                      <div
                        className="call-icon align-self-center mr-4"
                        onClick={() => {
                          if (this.props.sessionclose == false) {
                            this.setState({
                              callmodal: true,
                            });
                          } else {

                          }
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fas fa-phone-alt"></i>
                      </div>
                      <div
                        className="call-icon align-self-center mr-4"
                        onClick={() => {
                          if (this.props.sessionclose == false) {
                            this.setState({
                              videoModal: true,
                            });
                          } else {

                          }
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fas fa-video"></i>
                      </div>
                      {/* <div
                        className="call-icon align-self-center mr-4"
                        onClick={() => this.props.openCalling("video")}
                      >
                        <i className="fas fa-video"></i>
                      </div> */}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                        onClick={() => this.onCloseMethod()}
                      >
                        ×
                      </button>
                    </Col>
                    <Col md={12}>
                      <div className="card_txt" style={{overflowY: "scroll", maxHeight:'100px', padding:'10px 0'}}>
                        <p style={{color:"black"}}>Seller Name: {userData.firstName + " "+ userData.lastName}</p>
                        {/* <p style={{color:"black"}}>Start date: {moment(userData.start).format(
                                    "ddd [-] MMM DD YYYY"
                                )}</p> */}
                      </div>
                    </Col>
                    
                  </Row>
                </div>
              </CardBody>
              {/* chat list body */}
              <CardBody>
                

                <div className="chat-widget">
                 
                  <div className="chat-conversation">
                  
                    <SimpleBar style={{ maxHeight: "250px" }}>
                      <ul className="list-unstyled mb-0 pr-3">
                        
                        {this.state.msglist.length > 0 &&
                          this.state.msglist.map((item, index) => {
                            console.log("iteem==",item)
                            return (
                              <>
                                {
                                  item.type == "call" ?
                                    <li className="log_time">
                                      {moment(item.callTime).format('hh:mm A')}<br />
                                      <span>
                                        {
                                          item.callStatus == "Recieved" ?
                                            `Call ${item.callDuration}`
                                            :
                                            item.sender_id == this.props.selleruserid ?
                                              'Not Connected'
                                              :
                                              'Missed Call'
                                        }
                                      </span><br />
                                      <span>{moment(item.callTime).format('DD MMM, YYYY')}</span>
                                    </li>
                                    :
                                    <li
                                      className={
                                        item.sender_id === this.props.selleruserid
                                          ? "right"
                                          : ""
                                      }
                                      key={index}
                                    >
                                      {
                                        item.message != "" && item.msgimg != "" ?
                                          <div className="conversation-list">
                                            <div className="ctext-wrap">
                                              <div className="ctext-wrap-content py-1 px-2">
                                                <p className="mb-2">{item.message}</p>
                                                <a
                                                  href={HttpClient.IMG_URL +
                                                    item.msgimg}
                                                  target="_blank"
                                                >
                                                  <img
                                                    data-dz-thumbnail
                                                    src={
                                                      HttpClient.IMG_URL +
                                                      item.msgimg
                                                    }
                                                    className="avatar-md rounded bg-light"
                                                    alt="preview"
                                                    target="_blank"

                                                  />
                                                </a>
                                                <p className="mb-2">
                                                  {moment(item.send_time).fromNow()}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          item.message == "" && item.msgimg != "" ?
                                            <div className="conversation-list">
                                              <div className="ctext-wrap">
                                                <div className="ctext-wrap-content py-1 px-2">
                                                  <a
                                                    href={HttpClient.IMG_URL +
                                                      item.msgimg}
                                                    target="_blank"
                                                  >
                                                    <img
                                                      data-dz-thumbnail
                                                      src={
                                                        HttpClient.IMG_URL +
                                                        item.msgimg
                                                      }
                                                      className="avatar-md rounded bg-light"
                                                      alt="preview"
                                                    />
                                                  </a>
                                                  <p className="mb-2">
                                                    {moment(item.send_time).fromNow()}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                            :
                                            <div className="conversation-list">
                                              <div className="ctext-wrap">
                                                <div className="ctext-wrap-content py-1 px-2">
                                                  <p className="mb-2">{item.message}</p>
                                                  <p className="mb-2">
                                                    {moment(item.send_time).fromNow()}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                      }
                                    </li>
                                }
                              </>

                            );
                          })}
                        <li ref={this.messagesEndRef}></li>
                      </ul>
                    </SimpleBar>
                  </div>
                </div>
              </CardBody>
              {/* chat write area */}
              {this.props.sessionclose ? <div className="p-3 chat-input-section border-top"><Row><Col md={12}><label>The User has Ended This Chat Session, you cant use the audiocall & videocall features</label></Col></Row></div> :
                <div className="p-3 chat-input-section border-top">
                  <Row>
                    {/* upload image here */}
                    <Col md={12}>
                      <div>
                        {this.state.selectedFiles.length > 0 &&
                          this.state.selectedFiles.map((item, index) => {
                            return (
                              <div className="upld_img">
                                {item.type == "image/jpeg" ? (
                                  <img
                                    data-dz-thumbnail
                                    src={item.preview}
                                    className="avatar-sm rounded bg-light"
                                    alt="preview"
                                  />
                                ) : (
                                  <img
                                    data-dz-thumbnail
                                    src=""
                                    className="avatar-sm rounded bg-light"
                                    alt="preview"
                                  />
                                )}

                                <button
                                  className="close border-0 bg-transparent p-0 img_remove"
                                  onClick={() => {
                                    this.setState({
                                      firstFile: "",
                                      selectedFiles: [],
                                    });
                                  }}
                                >
                                  <i className="dripicons-cross" />
                                </button>
                              </div>
                            );
                          })}
                        {/* {this.state.videoloading?
                        <img
                        src="https://tenor.com/view/loading-waiting-please-wait-gif-14579561"
                        />:null} */}

                        {/* {this.state.videoloading ? (
                          <img
                            src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/loading-waiting-please-wait-gif-14579561"
                            style={{ height: 50, width: 50 }}
                          />
                        ) : null} */}
                      </div>
                    </Col>
                    {/* choose image */}
                    <Col md={1}>
                      <label htmlFor="fileUpload" className="my_fileUpload">
                        <i className="mdi mdi-folder-multiple-image"></i>
                        <input
                          type="file"
                          id="fileUpload"
                          className="fileUpload"
                          onChange={this.handlefilePick}
                        />
                      </label>
                    </Col>
                    {/* write message */}
                    <Col md={9}>
                      <div>
                        <Input
                          type="text"
                          className="form-control rounded chat-input pl-3"
                          placeholder="Enter Message..."
                          onChange={(val) =>
                            this.setState({
                              message: val.target.value,
                            })
                          }
                          value={this.state.message}
                        />
                      </div>
                    </Col>
                    {this.state.message != "" && this.state.firstFile != "" ? (
                      <Col md={2} xs={{ size: "auto" }}>
                        <Button
                          color="primary"
                          type="button"
                          onClick={this.uploadFile}
                          className="chat-send waves-effect waves-light font-size-12 w-100"
                        >
                          <span className="d-none d-sm-inline-block mr-1">
                            Send
                          </span>{" "}
                          <i className="mdi mdi-send"></i>
                        </Button>
                      </Col>
                    ) : this.state.message == "" && this.state.firstFile != "" ? (
                      <Col md={2} xs={{ size: "auto" }}>
                        <Button
                          color="primary"
                          type="button"
                          onClick={this.uploadFile}
                          className="chat-send waves-effect waves-light font-size-12 w-100"
                        >
                          <span className="d-none d-sm-inline-block mr-1">
                            Send
                          </span>{" "}
                          <i className="mdi mdi-send"></i>
                        </Button>
                      </Col>
                    ) : this.state.message != "" && this.state.firstFile == "" ? (
                      <Col md={2} xs={{ size: "auto" }}>
                        <Button
                          color="primary"
                          type="button"
                          onClick={() => this.SendMsg("")}
                          className="chat-send waves-effect waves-light font-size-12 w-100"
                        >
                          <span className="d-none d-sm-inline-block mr-1">
                            Send
                          </span>{" "}
                          <i className="mdi mdi-send"></i>
                        </Button>
                      </Col>
                    ) : (
                      //    this.state.videoloading?
                      //    <Col md={2} xs={{ size: "auto" }}>
                      //     <Spinner
                      //     color="primary"

                      //     className="chat-send waves-effect waves-light font-size-12 w-100"
                      //    >

                      //   </Spinner>
                      //  </Col>:
                      <Col md={2} xs={{ size: "auto" }}>
                        <Button
                          color="primary"
                          type="button"
                          onClick={() => {
                            alert("please enter message");
                          }}
                          className="chat-send waves-effect waves-light font-size-12 w-100"
                        >
                          <span className="d-none d-sm-inline-block mr-1">
                            Send
                          </span>{" "}
                          <i className="mdi mdi-send"></i>
                        </Button>
                      </Col>
                    )}
                  </Row>
                </div>}
            </Card>
          </div>
          {
            this.state.callmodal ? (
              <div
                className="modal fade single_modal show"
                id="exampleModal1"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ display: "block" }}
              >
                <AudioModal
                  status={true}
                  userData={reactLocalStorage.getObject("userData")}
                  chatRoomId={this.props.roomid}
                  remoteData={{
                    roomId: this.props.roomid,
                    userId: this.props.userid,
                    name: this.props.fullname,
                  }}
                  callBackcall1={this.callBackcall}
                  image=""
                />
              </div>
            ) : null
          }
          {/* videoModal */}
          {
            this.state.videoModal ? (
              <div
                className="modal fade single_modal show"
                id="exampleModal2"
                tabIndex="-1"
                aria-labelledby="exampleModal2Label"
                aria-hidden="true"
                style={{ display: "block" }}
              >
                <VideoModal
                  status={true}
                  chatRoomId={this.props.roomid}
                  remoteData={{
                    roomId: this.props.roomid,
                    userId: this.props.userid,
                    name: this.props.fullname,
                  }}
                  callBackVideo={this.callBackVideo}
                />
              </div>
            ) : null
          }
        </div >
      </div >
    );
  }
}
