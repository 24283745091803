import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withNamespaces } from "react-i18next";
import HttpClient from "./../../../utils/HttpClient";

// users
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import Dashboard from "../../../assets/images/users/blank-profile.png";

import { reactLocalStorage } from "reactjs-localstorage";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      username: "",
      image: "",
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  componentDidMount() {
    this.getuserData()
  }

  getuserData = async () => {
    let user = reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {


      this.setState({
        username: user.firstName + " " + user.lastName,
        image: user.profile
      })

    } else {
      // return false;
    }
  }

  render() {


    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block user-dropdown"
        >
          <DropdownToggle
            tag="button"
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
          >
            {this.state.image != "" ?
              <img
                className="rounded-circle header-profile-user mr-1"
                src={HttpClient.IMG_URL + this.state.image}
                alt="Header Avatar"
              /> :
              <img
                className="rounded-circle header-profile-user mr-1"
                src={Dashboard}
                alt="Header Avatar"
              />}
            <span className="d-none d-xl-inline-block ml-1 text-transform">
              {this.state.username}
            </span>
            {/* <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i> */}
          </DropdownToggle>
          {/* <DropdownMenu right>
            <DropdownItem href="/profile">
              <i className="ri-user-line align-middle mr-1"></i>{" "}
              {this.props.t("Profile")}
            </DropdownItem>

            <DropdownItem
              className="text-danger"
              onClick={() => {
                reactLocalStorage.setObject("userData", null);
                window.location.href = "/login"
              }}
            >
              <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>{" "}
              {this.props.t("Logout")}
            </DropdownItem>
          </DropdownMenu> */}
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(ProfileMenu);
