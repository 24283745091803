import React, { useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const TermsCondition = () => {
  const [breadcrumbItems, setBreadItems] = useState([
    { title: "My Spiritual", link: "#" },
    { title: "Terms & Conditions", link: "#" },
  ]);
  return (
    <React.Fragment>
      <div className="page-content  ">
        <div className="container-fluid">
          <Breadcrumbs
            title="Terms & Conditions"
            breadcrumbItems={breadcrumbItems}
          />
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="form-group">
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows={5}
                  defaultValue={"write here"}
                />
              </div>
              <div className="">
                <button type="button" className="btn btn-primary">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TermsCondition;
