import React, { useState, useEffect } from "react";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

//import ReactJsAlert from "reactjs-alert";

function Details(props) {

  useEffect(() => {

    console.log("oder Data", props.data);
  }, [props.data]);



  const onCloseMethod = async () => {
    props.onCloseCallBack(false);
  };

  // const { selectedOption } = this.state;
  return (
    <div
      className={props.status ? "modal show fade" : "modal fade"}
      id="add-new-task-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="add-new-task-modalLabel"
      aria-hidden="true"
      style={{ display: props.status ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="add-new-task-modalLabel">
              Service Details
            </h4>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => {
                onCloseMethod();
              }}
            >
              ×
            </button>
          </div>
          <div className="modal-body" style={{ height: "360px", overflowY: "auto" }}>
            <div className="px-2">

            </div>

            {props.data.service_data.map((item, index) => {
              let i = item.image.findIndex(
                (element) =>
                  element.ismain == true
              );

              return (<>
                <div className="d-md-flex  my-2  ">

                  <div className="order_pic ">
                    {i > -1 ?
                      <img
                        src={HttpClient.IMG_URL + item.image[i].preview}
                        alt="images"
                        className="rounded avatar-md card-img"
                      /> :
                      <img
                        src={HttpClient.IMG_URL + item.image[0].preview}
                        alt="images"
                        className="rounded avatar-md card-img"
                      />}

                  </div>
                  <div className="px-3 py-2" >
                    <h4>Service Name: {item.name}</h4>
                    <h6>Category Name: {item.category_name}</h6>
                    <h6>₹ {item.price}</h6>

                  </div>

                </div>
              </>);
            })}

            <div className="form-group row">

              <div className="col-md-4">
                <label htmlFor="task-title">User Name</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.buyer_data[0].firstname + " " + props.data.buyer_data[0].lastname}
                  readOnly
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="task-title">User Email</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.buyer_data[0].email}
                  readOnly
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="task-title">Mobile</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.buyer_data[0].phone}
                  readOnly
                />
              </div>
              <div className="col-md-8">
                <label htmlFor="task-title">Address 1</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.buyer_data[0].address1 + ", " + props.data.buyer_data[0].state + ", " + props.data.buyer_data[0].country + ", " + props.data.buyer_data[0].zip}
                  readOnly
                />
              </div>
              <div className="col-md-8">
                <label htmlFor="task-title">Address 2</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.buyer_data[0].address2 ? (props.data.buyer_data[0].address2 + ", " + props.data.buyer_data[0].state + ", " + props.data.buyer_data[0].country + ", " + props.data.buyer_data[0].zip) : ""}
                  readOnly
                />
              </div>


              {/* <div className="col-md-8">
                <label htmlFor="task-title">Address</label>


                <textarea
                  style={{ height: "37px" }}
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.address1 + ", " + props.data.state + ", " + props.data.zip + ", " + props.data.country}
                  readOnly

                />
              </div> */}

              {/* <div className="col-md-4">

                <label htmlFor="task-title">Payment Type</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.payment_type}
                  readOnly
                />
              </div> */}
              <div className="col-md-4">
                <label htmlFor="task-title">Order ID</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.order_id}
                  readOnly
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="task-title">Booking Date</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={moment(props.data.booking_date).format("lll")}
                  readOnly
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="task-title">Booked At</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={moment(props.data.date_of_booking).format("lll")}
                  readOnly
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="task-title">Sub Total</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.service_data[0].price}
                  readOnly
                />
              </div>



            </div>


            <div className="text-right">
              <button
                type="button"
                className="btn btn-warning mr-3"
                data-dismiss="modal"
                onClick={() => {
                  onCloseMethod();
                }}
              >
                Cancel
              </button>
              {/* {this.state.verifyotp == this.props.otp ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  // className="close"

                  onClick={() => {
                    this.AddDriver();
                  }}
                  data-dismiss="modal"
                >
                  verify
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  // className="close"

                  onClick={() => {
                    this.AddDriver();
                  }}
                  data-dismiss="modal"
                >
                  verify
                </button>
              )} */}
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}
export default Details;
