import React, { Component } from "react";
import { CSVLink } from "react-csv";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import ReactStars from "react-rating-stars-component";
// import Modal from "./pendingmodal";
//import ModalPendingBooking from "./ModalPendingBooking";

const header = [
  { label: "Booked by", key: "type" },
  { label: "Order Id", key: "oder_id" },
  { label: "Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Day of Review", key: "day" },
  { label: "Date of Booking", key: "bookdate" },
  { label: "Rating", key: "new_rating" },
]
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Rating & Review List", link: "#" },
        { title: "Manage Rating & Review List", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},

      image_select: false,
      img_url: "",
      isOpenLike: false,

      bookingid: "",
      modaluserid: "",
      cancelBooking: false,
      pincode: "",
    };
    // this.handleDefault = this.handleDefault.bind(this);
  }



  componentDidMount = async () => {
    this.checkUser();
  };

  callbackLike = async () => {
    this.state.isOpenLike = false;
    this.setState({});

    console.log(this.state.isOpenLike);
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    console.log("user", user);
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user._id,
      });
      this.fetchData(user._id);
    }

  };

  fetchData = async (id) => {
    console.log("id", id);
    let result = await HttpClient.requestData("seller-service/reviews/" + id, "GET");
    console.log("result", result);
    if (result && result.status) {

      let data = [];
      let i = 1;

      result.data.forEach((element, index) => {
        //let image=element.shopservice_data.length>0?element.shopservice_data[0].image:"";
        let fname = element.user_data ? element.user_data.firstName : "";
        let lname = element.user_data ? element.user_data.lastName : "";

        let rows = {
          sl: i,
          name: fname + " " + lname,
          email: element.user_data ? element.user_data.email : "",
          day: moment(element.rev_date).format("dddd"),

          bookdate: moment(element.rev_date).format("DD-MM-YYYY"),
          orderid: "#" + element.order_id,
          new_rating: element.rating,
          rating: (<ReactStars
            count={5}
            value={element.rating}
            edit={false}
            isHalf={true}
            size={25}
            activeColor="#ffd700"
          />),
          type: element.user_data ? element.user_data.type : "No type",
          //cat_name:element.shopservice_data.length>0?element.shopservice_data[0].category_name:"",
          //service:element.shopservice_data.length>0?element.shopservice_data[0].name:"",
          //     img: (
          //   <div>
          //     <img
          //       src={"http://128.199.25.86:5010/" +image}
          //       alt="images"
          //       className="rounded avatar-md card-img"
          //     />
          //   </div>
          // ),



          cancelbooking: (
            <>
              <button
                title="Cancel"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.cancel(element, index);
                }}
              >
                cancel Booking
              </button>
            </>
          ),

          View: (
            <>
              <button
                className="btn btn-success mr-2"
                onClick={() => {
                  this.setState({
                    isOpenLike: true,
                    modaluserid: element._id,
                  });
                }}
              >
                View
              </button>
            </>
          ),

          assign:

            <button

              className={"btn btn-success mr-2"}
              onClick={() => {
                this.accept(element, index)

              }}
            >
              Accept Booking
            </button>,


          // action: (
          //   <>
          //     <button
          //       title="Delete"
          //       className="btn btn-danger mr-2"
          //       onClick={() => {
          //         this.delete(element, index);
          //       }}
          //     >
          //       <i className="fa fa-trash" />
          //     </button>
          //     <button
          //       title="Edit"
          //       className="btn btn-primary"
          //       onClick={() => {
          //         this.edit(element, index);
          //       }}
          //     >
          //       <i className="fa fa-edit" />
          //     </button>
          //   </>
          // ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  accept = async (item, index) => {
    console.log("Cancel", item, index);

    let result = await HttpClient.requestData(
      "seller-service/accept-booking/" + item._id,
      "PUT",

    );

    console.log("Booking Accept", result);
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "Booking Accepted Successfully",
        type: "success",

      });


      window.location.href = "/accept-booking";
    } else {
      this.setState({
        alert: true,
        message: result.message,
        type: "danger",
      });



      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
    }
  };

  cancel = async (item, index) => {
    console.log("Cancel", item, index);

    let result = await HttpClient.requestData(
      "seller-service/reject-booking/" + item._id,
      "PUT",

    );

    console.log("Booking Cancel", result);
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "Booking Cancelled Successfully",
        type: "success",

      });



      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      window.location.href = "/cancel-booking";
    } else {
      this.setState({
        alert: true,
        message: "something went wrong",
        type: "danger",
      });
    }
  };

  render() {
    const data = {

      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Booked By",
          field: "type",
          sort: "asc",
          width: 270,
        },
        {
          label: "Order ID",
          field: "orderid",
          sort: "asc",
          width: 270,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 270,
        },
        {
          label: "Day of Review",
          field: "day",
          sort: "asc",
          width: 270,
        },

        {
          label: "Date of Review",
          field: "bookdate",
          sort: "asc",
          width: 270,
        },
        {
          label: "Rating",
          field: "rating",
          sort: "asc",
          width: 270,
        },
        /*  {
           label: "Category Name",
           field: "cat_name",
           sort: "asc",
           width: 270,
         },
          {
           label: "Service Name",
           field: "service",
           sort: "asc",
           width: 100,
         } */
        // {
        //   label: "Image",
        //   field: "img",
        //   sort: "asc",
        //   width: 100,
        // },



      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col lg={12}>
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.message}
                  </UncontrolledAlert>
                ) : null}
              </Col>
            </Row>

            <Row>
              <Col xl={12}>
                {this.state.data.length > 0 ?
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"Review & Rating List.csv"}
                    className="btn btn-success">
                    Export Report in CSV
                  </CSVLink> : null}
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>{" "}
          {/* {this.state.isOpenLike ? (
            <Modal
              isOpen={this.state.isOpenLike}
              userid={this.state.userId}
              modaluserid={this.state.modaluserid}
              closeCallback={this.callbackLike}
            />
          ) : null} */}
        </div>

      </React.Fragment>
    );
  }
}

export default FormElements;