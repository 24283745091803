//Modal.js(For Like)

import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  Row,
  Col,
  Card,
  UncontrolledAlert,
} from "reactstrap";
import Dropzone from "react-dropzone";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";

class ModalPendingBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
      message: "",
      type: "",
      id: this.props.bookingid,
      name: "",
      email: "",
      result: [],
      data: [],
      userdata: {},
      servicedata: {},
      cartData: {},
      suggested_cat: "",
      suggested_decs: "",
      images: "",
      uploaded_images: [],
    };
  }

  componentDidMount() {
    // this.fetchData();
  }

  //   fetchData = async () => {

  //      let result = await HttpClient.requestData("seller-service/new-bookings/"+this.props.userid, "GET");
  //     console.log(result);
  //     if (result && result.status ) {
  //       this.setState({ result: result.data });
  //       let rr = result.data.filter((item) => item._id == this.props.modaluserid);
  //       console.log("PendingBooking", rr[0]);
  //       this.setState({
  //         data: rr[0],
  //         userdata:rr[0].user_data.length>0?rr[0].user_data[0]:"",
  //         servicedata:rr[0].shopservice_data.length>0?rr[0].shopservice_data[0]:"",
  //         cartData:rr[0].cart_data.length>0?rr[0].cart_data[0]:"",
  //       });
  //       console.log("Pending Booking", this.state.data);
  //        console.log("userdata", this.state.userdata);
  //        console.log("servicedata", this.state.servicedata);
  //     }
  //   };

  handleAcceptedFiles = (files) => {
    // console.log("object", event.target.files[0]);
    // setprofile(event.target.files[0]);
    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
    };
    console.log("image", files[0]);
    let arr = [];
    arr.push(file);
    this.setState({
      images: files[0],
      uploaded_images: arr,
    });
    console.log("uploaded_images", this.state.uploaded_images);
  };
  async remove_image(item, index) {
    // this.state.selectedFiles.pop(index);
    // this.state.images.pop(index);
    this.setState({
      images: {},
    });
    this.state.uploaded_images.pop(index);
    this.setState({});
  }
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  Suggest = async () => {
    if (
      this.state.suggested_cat == "" &&
      this.state.suggested_decs == "" 
    ) {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    } else if (this.state.uploaded_images.length < 0) {
      this.setState({
        alert: true,
        message: "Please Select Atleast One Image.",
        type: "warning",
      });
    } else {
      // let data = null;
      let result = null;
      let data = new FormData();
      data.append("name", this.state.suggested_cat);
      data.append("description", this.state.suggested_decs);
      data.append("image", this.state.images);
      result = await HttpClient.fileUplode(
        "suggested-category",
        "POST",
        data
      );
      console.log("suggest",result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message:
            "Thank you for Suggesting us the category and helping to build the community, we will add the category once it is reviewed",
          type: "success",
          suggested_cat: "",
          suggested_decs: "",
        });
        setTimeout(() => {
          this.props.closeCallback();
        }, 6000);
      } else {
        this.setState({
          alert: true,
          message: result.message,
          type: "danger",
        });
      }
    }
  };

  render() {
    return (
      <Modal isOpen={this.props.isOpen}>
        <ModalHeader tag="h5" style={{ color: "DodgerBlue" }}>
          Suggest Category
        </ModalHeader>
        {this.state.alert ? (
          <UncontrolledAlert
            color={this.state.type}
            className="alert-dismissible fade show"
            role="alert"
          >
            {this.state.type == "warning" ? (
              <i className="mdi mdi-alert-outline mr-2"></i>
            ) : this.state.type == "success" ? (
              <i className="mdi mdi-check-all mr-2"></i>
            ) : this.state.type == "danger" ? (
              <i className="mdi mdi-block-helper mr-2"></i>
            ) : null}
            {this.state.message}
          </UncontrolledAlert>
        ) : null}
        <ModalBody
          scrollable={true}
          style={{ maxHeight: "350px", overflowY: "auto" }}
        >
          <Container>
            <Row>
              <Col sm={{ size: "auto" }} tag="h4">
                {this.props.title}
              </Col>{" "}
              {/* <Col sm={{ size: "auto" }} tag="h4">
                (Total {this.state.userListLiked.length} Likes)
              </Col> */}
            </Row>

            <FormGroup row>
              <Label
                htmlFor="example-text-input"
                className="col-md-2 col-form-label"
              >
                 Category Name
              </Label>
              <Col md={10}>
                <Input
                  className="form-control"
                  type="text"
                  onChange={(val) => {
                    this.setState({ suggested_cat: val.target.value });
                  }}
                  id="example-text-input"
                  value={this.state.suggested_cat}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label
                htmlFor="example-text-input"
                className="col-md-2 col-form-label"
              >
                 Description
              </Label>
              <Col md={10}>
                <textarea
                  className="form-control"
                  type="text"
                  onChange={(val) => {
                    this.setState({ suggested_decs: val.target.value });
                  }}
                  id="example-text-input"
                  value={this.state.suggested_decs}
                ></textarea>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                htmlFor="example-text-input"
                className="col-md-2 col-form-label"
              >
                Image
              </Label>
              <Col md={10}>
                <Dropzone
                  onDrop={(acceptedFiles) =>
                    this.handleAcceptedFiles(acceptedFiles)
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick mt-2"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-3">
                          <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                        </div>
                        <h4>Drop files here or click to upload.</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className="dropzone-previews mt-3" id="file-previews">
                  {this.state.uploaded_images.map((f, i) => {
                    return (
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                // alt={f.name}
                                src={f.preview}
                              />
                            </Col>
                            <Col>
                              <Button
                                className="btn-icon"
                                color="danger"
                                style={{ marginRight: 25 }}
                                onClick={(e) => this.remove_image(f, i)}
                              >
                                {" "}
                                <span className="btn-icon-label">
                                  <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                </span>{" "}
                                Remove
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    );
                  })}
                </div>
              </Col>
            </FormGroup>
          </Container>
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              this.Suggest();
            }}
          >
            Suggest
          </Button>
          <Button color="secondary" onClick={() => this.props.closeCallback()}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalPendingBooking;
